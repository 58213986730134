import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ServiceDetails extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__page-details-area ltn__service-details-area mb-105">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="ltn__page-details-inner ltn__service-details-inner">
                <div className="ltn__blog-img">
                  <img
                    src={publicUrl + "assets/img/service/Services.jpg"}
                    alt="Image"
                  />
                </div>
                <h2>Property Management</h2>
                <p>
                  Property management involves overseeing the operation,
                  control, and maintenance of real estate properties. Our team
                  of experts is dedicated to ensuring that your properties are
                  well-maintained and profitable. From tenant management to
                  property maintenance, we handle it all with professionalism
                  and care.
                </p>
                <h2>Mortgage Service</h2>
                <p>
                  Need help securing a mortgage for your dream home? Our
                  mortgage service is designed to guide you through the
                  intricate process of obtaining a mortgage. Whether you're a
                  first-time buyer or looking to refinance, our experienced team
                  will work tirelessly to find the best mortgage solution
                  tailored to your needs.
                </p>
                <h2>Consulting Service</h2>
                <p>
                  Our consulting service offers expert advice and guidance on a
                  wide range of topics, including financial planning, property
                  investment, and business development. Whether you need
                  strategic advice or practical solutions, our consultants are
                  here to help you navigate complex challenges and achieve your
                  goals.
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <img
                      src={publicUrl + "assets/img/service/0001.jpg"}
                      alt="image"
                    />
                    {/* <label>Image caption here.</label> */}
                  </div>
                  <div className="col-lg-6">
                    <img
                      src={publicUrl + "assets/img/service/0003.jpg"}
                      alt="image"
                    />
                  </div>
                </div>
                <h2>Home Buying</h2>
                <p>
                  Ready to purchase your dream home? Our home buying service
                  simplifies the process of finding and purchasing your ideal
                  property. From property search to negotiation and closing,
                  we'll be with you every step of the way, ensuring a smooth and
                  stress-free home buying experience.
                </p>
                <h2>Home Selling</h2>
                <p>
                  Looking to sell your home? Our home selling service is
                  designed to help you achieve the best possible price in the
                  shortest amount of time. With our strategic marketing approach
                  and personalized service, we'll attract qualified buyers and
                  negotiate favorable terms for the sale of your property.
                </p>
                <h2>Property Shield Service</h2>
                <p>
                  Protect your investment with our property shield service. We
                  offer comprehensive insurance solutions tailored to the unique
                  needs of property owners. From property damage to liability
                  protection, our insurance plans provide peace of mind and
                  financial security for property owners.
                </p>
                {/* <div className="ltn__service-list-menu text-uppercase mt-50 d-none">
                  <ul>
                    <li>
                      <i className="fas fa-car" /> Front Brakes Repair{" "}
                      <span className="service-price">
                        $225.95 <span>Plus Parts</span>
                      </span>{" "}
                    </li>
                    <li>
                      <i className="fas fa-life-ring" /> Rear Brakes Repair{" "}
                      <span className="service-price">
                        $225.95 <span>Plus Parts</span>
                      </span>{" "}
                    </li>
                    <li>
                      <i className="fas fa-cog" /> Axle{" "}
                      <span className="service-price">
                        $225.95 <span>Plus Parts</span>
                      </span>{" "}
                    </li>
                    <li>
                      <i className="fas fa-car" /> Starters / Alternators{" "}
                      <span className="service-price">
                        $225.95 <span>Plus Parts</span>
                      </span>{" "}
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4">
              <aside className="sidebar-area ltn__right-sidebar">
                {/* <div className="widget-2 ltn__menu-widget ltn__menu-widget-2 text-uppercase">
                  <ul className="go-top">
                    <li>
                      <Link to="/service">
                        Property Management{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/service">
                        Mortgage Service{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service">
                        Consulting Service{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service">
                        Home Buying{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service">
                        Home Selling{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/service">
                        Escrow Services{" "}
                        <span>
                          <i className="fas fa-arrow-right" />
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div> */}
                {/* Newsletter Widget */}
                <div className="widget ltn__search-widget ltn__newsletter-widget">
                  <h6 className="ltn__widget-sub-title">// subscribe</h6>
                  <h4 className="ltn__widget-title">Get Newsletter</h4>
                  <form >
                    <input type="text" name="search" placeholder="Search" />
                    <button >
                      <i className="fas fa-search" />
                    </button>
                  </form>
                  <div className="ltn__newsletter-bg-icon">
                    <i className="fas fa-envelope-open-text" />
                  </div>
                </div>
                {/* Banner Widget */}
                <div className="widget ltn__banner-widget go-top">
                  <Link to="/shop">
                    <img
                      src={publicUrl + "assets/img/service/0002.jpg"}
                      alt="Banner Image"
                    />
                  </Link>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceDetails;
