import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {
    render() {
        // Update latitude and longitude coordinates based on your desired location
        const latitude = 16.5061749;
        const longitude = 80.9971937;

        return (
            <div className="google-map mb-120">
                <iframe
                    src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d382055.8784219961!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a34ba8a9d244f61%3A0x4cf68c00719ebd6c!2sAmaravathi%2C%20Andhra%20Pradesh!5e0!3m2!1sen!2sin!4v1645330423288!5m2!1sen!2sin`}
                    width="100%"
                    height="100%"
                    frameBorder={0}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                />
            </div>
        );
    }
}

export default Map;