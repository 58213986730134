import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { FaHome } from "react-icons/fa";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { GiTakeMyMoney } from "react-icons/gi";
class FeaturesV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let customClass = this.props.customClass ? this.props.customClass : "";

    return (
      <div className={customClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Services
                </h6>
                <h1 className="section-title">Our Primary Emphasis</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  <FaHome />
                  {/* <img src={publicUrl + "assets/img/icons/icon-img/21.png"} alt="#" /> */}
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Buy a Property</Link>
                  </h3>
                  <p>
                    Discover over 1K+ homes for sale available on our website.
                    We can match you with a house you'll want to call home.
                  </p>
                  <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                <div className="ltn__feature-icon">
                  <FaHandHoldingDollar />
                  {/* <img src={publicUrl + "assets/img/icons/icon-img/22.png"} alt="#" /> */}
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Rent a Property</Link>
                  </h3>
                  <p>
                    Explore over 1K+ homes for rent available on our website. We
                    can match you with a rental you'll love.
                  </p>
                  <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div className="ltn__feature-icon">
                  {/* <img src={publicUrl + "assets/img/icons/icon-img/23.png"} alt="#" /> */}
                  <GiTakeMyMoney />
                </div>
                <div className="ltn__feature-info">
                  <h3>
                    <Link to="/service-details">Sell a Property</Link>
                  </h3>
                  <p>
                    List your property for sale on our platform. With over 1K+
                    active users, finding the right buyer is easier than ever.
                  </p>
                  <Link className="ltn__service-btn" to="/service-details">
                    Find A Home <i className="flaticon-right-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturesV1;
