import * as Yup from 'yup';

export const registrationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone Number is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required')
});

export const loginSchema = Yup.object().shape({
    email: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone Number is required'),
    password: Yup.string().required('Password is required')
});



// Define the Yup schema for validation
export const Listingschema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    propertyType: Yup.string().required('Property type is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    location: Yup.string().required('Location is required'),
    subLocation: Yup.string().required('Sub Location is required'),
    landmark: Yup.string(),
    pincode: Yup.number().required('Pincode is required'),
    propertyState: Yup.string().required('Property state is required'),
    processionStatus: Yup.string().required("possession status is required"),
    plotPrice: Yup.number().required('Price is required'),
    maintenanceCharge: Yup.number().required('Maintenance charge is required'),
    width: Yup.number(),
    length: Yup.number(),
    widthOfFacingRoad: Yup.number(),
    bhk: Yup.number(),
    bathrooms: Yup.number(),
    // furnishType: Yup.string().required('Furnish type is required'),
    covered2WheelParking: Yup.number(),
    covered4WheelParking: Yup.number(),
    open2WheelParking: Yup.number(),
    open4WheelParking: Yup.number(),
    // GpsLocationLink: Yup.string().required("GpsLocationLink is required to be set to a valid location in the Google"),
    Facing: Yup.string().required("Facing is required")
    // builtupArea: Yup.number().required('Builtup area is required'),
    // carpetArea: Yup.number().required('Carpet area is required'),
});

