import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import Copyright from "./copyright";
import Instence from "../../utils/Instence";
import Swal from "sweetalert2";

const Footer_v1 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);


const GetVisitCount = async() => {
  try {
    const response = await Instence.get("Blogs/increment-view-count");
    if(response.status === 200){
      console.log("visit count", response.data)
      
    }
  } catch (error) {
    console.log(error);
  }
}

useEffect(() => {
  // GetVisitCount();
},[])





  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const bodyHtml = `
  <div style="font-family: Arial, sans-serif; font-size: 16px; color: #333;">
    <div style="background-color: #f8f8f8; padding: 20px; border-radius: 5px; border: 1px solid #ddd;">
      <h2 style="margin-bottom: 20px;">New Email Inquiry</h2>
      <p style="margin-bottom: 10px;"><strong>Email:</strong> ${email}</p>
      <p style="margin-bottom: 10px;">Thank you for your interest. We will get back to you as soon as possible.</p>
    </div>
  </div>
`;

      // Send email using Axios
      const formData = new FormData();
      formData.append("To", "WaytoLandmark@webmail.com"); // Update with your agent's email
      formData.append("Subject", "Service Inquiry");
      formData.append("Body", bodyHtml);

      const response = await Instence.post("/Email/SendMail", formData);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Email sent successfully!",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setEmail("");
      } else {
        setError("Failed to send email");
      }
    } catch (error) {
      setError("An error occurred while sending email");
    }
  };

  return (
    <footer className="ltn__footer-area  ">
      <div className="footer-top-area  section-bg-2 plr--5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img
                      src={publicUrl + "assets/img/wayLogo2.png"}
                      alt="Logo"
                    />
                  </div>
                </div>
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-placeholder" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          way2landmark realtors Pvt Ltd,opposite to hosanna
                          mandir, Rayapudi,522237
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+0123-456789">+91 6303816633</a>
                        </p>
                        <p>
                          <a href="tel:+0123-456789">+91 7013901494</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-mail" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:example@example.com">
                            WaytoLandmark@webmail.com
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="ltn__social-media mt-20">
                  <Social />
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Company</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    {/* <li>
                      <Link to="/blog-grid">Blog</Link>
                    </li> */}
                    <li>
                      <Link to="/privacy-policy">Privacy & Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Services</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/about">Terms &amp; Conditions</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
              <div className="footer-widget footer-newsletter-widget">
                <h4 className="footer-title">Newsletter</h4>
                <p>
                  Subscribe to our weekly Newsletter and receive updates via
                  email.
                </p>
                <div className="footer-newsletter">
                  <form onSubmit={handleSubmit} action="#">
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email*"
                      required
                    />
                    <div className="btn-wrapper">
                      <button className="emailsend_btn" type="submit">
                        <i className="fas fa-location-arrow" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
};

export default Footer_v1;
