import React, { Component } from "react";
import { Link } from "react-router-dom";
import img from "../../Img/team/properties.jpg"
import "../../Css/Global.css"

class VideoV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <div className="ltn__video-popup-area ltn__video-popup-margin---">
        <div
          className="ltn__video-bg-img ltn__video-popup-height-600---  bg-image bg-fixed ltn__animation-pulse1 Bg_Video_img23"
        >
          <a
            className="ltn__video-icon-2 ltn__video-icon-2-border---"
            href="https://www.youtube.com/embed/4jnzf1yj48M?autoplay=1&showinfo=0"
            data-rel="lightcase:myCollection"
          // <iframe width="1263" height="480" src="" title="DESIGNER RESIDENCE | CINEMATIC REAL ESTATE VIDEO IN 4K | SONY FX6" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          >
            <i className="fa fa-play" />
          </a>
        </div>
      </div >
    );
  }
}

export default VideoV1;
