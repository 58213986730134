import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import Sidebar from "./shop-sidebar";
import Instence from "../../utils/Instence";
import { Pagination } from "react-bootstrap";
import dummyProperty from "../../Img/team/properties.jpg";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
const ShopGridV1 = ({ searchData, searchptype, searchParams }) => {

  console.log(searchData) 
  console.log(searchptype)
  console.log(searchParams)

  const history = useHistory();
  const [likedItemIds, setLikedItemIds] = useState([]);

  useEffect(() => {
    // Retrieve likedItemIds from sessionStorage when the component mounts
    const storedLikedItemIds = JSON.parse(sessionStorage.getItem('likedItemIds'));
    if (storedLikedItemIds) {
      setLikedItemIds(storedLikedItemIds);
    }
  }, []);

  useEffect(() => {
    // Save likedItemIds to sessionStorage whenever it changes
    sessionStorage.setItem('likedItemIds', JSON.stringify(likedItemIds));
  }, [likedItemIds]);

  const toggleLike = async (itemId) => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));

    // Check if userData exists
    if (!userData) {
      history.push('/login');
      console.error('User data not found in session storage.');
      return; // Exit function if userData is not available
    }

    // Parse userData as JSON
    const userId = userData.id;

    try {
      if (likedItemIds.includes(itemId)) {
        // Unlike the item
        setLikedItemIds(likedItemIds.filter((id) => id !== itemId));
        // Call API to remove like
        await removePropertyLiked(userId, itemId);
      } else {
        // Like the item
        setLikedItemIds([...likedItemIds, itemId]);
        // Call API to like the property
        await addPropertyLiked(userId, itemId);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      // Handle error as needed, e.g., show an error message
    }
  };

  const addPropertyLiked = async (userId, propId) => {
    try {
      const response = await axios.post(
        'https://way2landmark20240705114540.azurewebsites.net/api/Property/PropertyLiked',
        {
          userId: userId,
          propId: propId
        },
      );
      console.log('Property liked successfully:', response.data);
      // You can handle the response or perform any additional actions here
    } catch (error) {
      console.error('Error liking property:', error);
      throw error; // Propagate the error to the caller
    }
  };

  const removePropertyLiked = async (userId, propId) => {
    try {
      const response = await axios.post(
        `https://way2landmark20240705114540.azurewebsites.net/api/Property/DeleteLikedProp/${propId}`,
      );
      console.log('Property unliked successfully:', response.data);
      // You can handle the response or perform any additional actions here
    } catch (error) {
      console.error('Error unliking property:', error);
      throw error; // Propagate the error to the caller
    }
  };

  const [propdata, setPropData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSearchData, setShowSearchData] = useState(true);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [sortBy, setSortBy] = useState("default");
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    // Fetch properties
    fetchProperties();
  }, []);

  useEffect(() => {
    // Sort data when dependencies change
    sortData();
  }, [showSearchData, sortBy, selectedTypes, propdata, searchData]);

  useEffect(() => {
    // Pre-check checkboxes based on searchptype
    if (searchptype && searchptype.length > 0) {
      setSelectedTypes(searchptype);
    }
  }, [searchptype]);

  const fetchProperties = async () => {
    try {
      const response = await Instence.get(`/Property/GetAllOnlyTrue`);
      setPropData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const convertPriceToNumber = (price) => {
    const numericPriceString = price.replace(/[^\d.]/g, "");
    let numericPrice = parseFloat(numericPriceString);
    if (price.toLowerCase().includes("k")) {
      numericPrice *= 1000;
    } else if (price.toLowerCase().includes("l")) {
      numericPrice *= 100000;
    } else if (price.toLowerCase().includes("cr")) {
      numericPrice *= 10000000;
    }
    return numericPrice;
  };

  const sortData = () => {
    let sortedData = [...dataToShow];
    switch (sortBy) {
      case "new_arrivals":
        sortedData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      case "price_low_to_high":
        sortedData.sort(
          (a, b) =>
            convertPriceToNumber(a.propertyPrice) -
            convertPriceToNumber(b.propertyPrice)
        );
        break;
      case "price_high_to_low":
        sortedData.sort(
          (a, b) =>
            convertPriceToNumber(b.propertyPrice) -
            convertPriceToNumber(a.propertyPrice)
        );
        break;
      default:
        break;
    }
    setSortedData(sortedData);
  };

  const handleSeeAllProperties = () => {
    setShowSearchData(false);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setShowSearchData(false);
    const { value, checked } = e.target;
    // Ensure selectedTypes is always an array
    let updatedSelectedTypes = [...selectedTypes];
    if (checked) {
      updatedSelectedTypes.push(value);
    } else {
      updatedSelectedTypes = updatedSelectedTypes.filter(
        (type) => type !== value
      );
    }
    setSelectedTypes(updatedSelectedTypes);
  };

  let dataToShow = showSearchData ? searchData : propdata;


  // dataToShow = dataToShow?.filter((item) => {
  //   console.log(item)
    
  //   if (selectedTypes.length === 0 || selectedTypes.includes(item.propertyType)) {
  //     console.log(item.propertyType)
      
  //     if (
  //       (searchParams.listingType === 'Sell' && item.proprtyState === 'Sell') ||
  //       (searchParams.listingType === 'Rent' && item.proprtyState === 'Rent')
  //     ) {
  //       return item.proprtyState === searchParams.listingType;
  //     }
  //   }
  //   return false; 
  // });

  console.log("DDDDDDDDDD", dataToShow)

  dataToShow = dataToShow?.filter((item) => {
    // Check if the property matches the city and subLocation from searchParams
    const matchesLocation = item.city == searchParams.selectedCity && item.subLocation == searchParams.selectedSubLocation;
  console.log("Matches Location", matchesLocation)
  console.log("City", item.city)
  console.log("SubLocation", item.subLocation)
    // If no types are selected, just return properties that match the location
    if (selectedTypes.length === 0) {
      return matchesLocation;
    }
  
    // If types are selected, return properties that match both the location and the selected types
    const matchesType = selectedTypes.includes(item.propertyType);
  
    // Also, check if the listingType matches the proprtyState
    const matchesListingType =
      (searchParams.listingType === "Sell" && item.proprtyState === "Sell") ||
      (searchParams.listingType === "Rent" && item.proprtyState === "Rent");
  
    // Return the item only if it matches the location, selected types, and listing type
    return matchesLocation && matchesType && matchesListingType;
  });
  
  console.log("After filter", dataToShow);

  // ********************************** Pagination Logic Here *************************************************
console.log("..../", sortedData)
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(4);
  // Logic to calculate current properties to display based on pagination
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = sortedData.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );
  // Logic to handle pagination page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Logic to generate pagination items
  const paginationItems = [];
  for (
    let number = Math.max(1, currentPage - 2);
    number <=
    Math.min(currentPage + 2, Math.ceil(sortedData.length / propertiesPerPage));
    number++
  ) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => paginate(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  //! Add ellipsis to the pagination if necessary
  if (currentPage > 3) {
    paginationItems.unshift(<Pagination.Ellipsis key="start-ellipsis" />);
  }
  if (currentPage < Math.ceil(sortedData.length / propertiesPerPage) - 2) {
    paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
  }
  const parseDimension = (dimension) => {
    const match = dimension.match(/^(\d+(\.\d+)?)(\s*(sqft|sqyd|sqm|sqin))$/);
    if (match) {
      return { value: parseFloat(match[1]), unit: match[4] };
    }
    return null;
  };

  const calculateArea = (width, length) => {
    const parsedWidth = parseDimension(width);
    const parsedLength = parseDimension(length);

    if (parsedWidth && parsedLength && parsedWidth.unit === parsedLength.unit) {
      const area = parsedWidth.value * parsedLength.value;
      return `${area.toFixed(2)} ${parsedWidth.unit}`;
    }
    return "N/A"; // Or handle unit conversion if needed
  };

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter">
        <div className="container">
          <div className="row">
            <div className="col-lg-4  mb-100">
              <aside className="sidebar ltn__shop-sidebar">
                <h3 className="mb-10">Advance Information</h3>
                <label className="mb-30">
                  <small>About {propdata.length} results (0.62 seconds) </small>
                </label>
                <div className="widget ltn__menu-widget">
                  <h4 className="ltn__widget-title">Property Type</h4>
                  <ul>
                    <li>
                      <label className="checkbox-item">
                        Apartment
                        <input
                          type="checkbox"
                          value="Apartment"
                          checked={selectedTypes.includes("Apartment")}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark" />
                      </label>
                      {/* <span className="categorey-no">3,924</span> */}
                    </li>
                    <li>
                      <label className="checkbox-item">
                        Flat
                        <input
                          type="checkbox"
                          value="Flat"
                          checked={selectedTypes.includes("Flat")}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark" />
                      </label>
                      {/* <span className="categorey-no">3,610</span> */}
                    </li>
                    <li>
                      <label className="checkbox-item">
                        House
                        <input
                          type="checkbox"
                          value="Independent House"
                          checked={selectedTypes.includes("Independent House")}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark" />
                      </label>
                      {/* <span className="categorey-no">2,912</span> */}
                    </li>
                    <li>
                      <label className="checkbox-item">
                        Villa
                        <input
                          type="checkbox"
                          value="Villa"
                          checked={selectedTypes.includes("Villa")}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark" />
                      </label>
                      {/* <span className="categorey-no">2,687</span> */}
                    </li>
                    <li>
                      <label className="checkbox-item">
                        Open Plot
                        <input
                          type="checkbox"
                          value="Plot"
                          checked={selectedTypes.includes("Plot")}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark" />
                      </label>
                      {/* <span className="categorey-no">1,853</span> */}
                    </li>
                    <li>
                      <label className="checkbox-item">
                        Agricultural Land
                        <input
                          type="checkbox"
                          value="Agricultural Land"
                          checked={selectedTypes.includes("Agricultural Land")}
                          onChange={handleCheckboxChange}
                        />
                        <span className="checkmark" />
                      </label>
                      {/* <span className="categorey-no">893</span> */}
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="col-lg-8  mb-100">
              <div className="ltn__shop-options">
                <ul className="justify-content-start">
                  <li>
                    <div className="ltn__grid-list-tab-menu ">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_product_grid"
                        >
                          <i className="fas fa-th-large" />
                        </a>
                        <a data-bs-toggle="tab" href="#liton_product_list">
                          <i className="fas fa-list" />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="showing-product-number text-right">
                      <span>Showing 1–12 of 18 results</span>
                    </div>
                  </li>
                  <li>
                    <div className="short-by text-center">
                      <select
                        className="nice-select"
                        onChange={handleSortChange}
                        value={sortBy}
                      >
                        <option value="default">Default Sorting</option>
                        <option value="new_arrivals">
                          Sort by new arrivals
                        </option>
                        <option value="price_high_to_low">
                          Sort by price: high to low
                        </option>
                        <option value="price_low_to_high">
                          Sort by price: low to high
                        </option>
                      </select>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  {/* ************************ Grid View Of Items *************************************** */}

                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    {loading ? (
                      <div class="loading">Loading&#8230;</div>
                    ) : (
                      <div>
                        <div className="row">
                          {currentProperties.length === 0 ? (
                            <div className="col-12">
                              <p>
                                No properties match the selected types please
                                select property type or refresh
                              </p>
                            </div>
                          ) : (
                            <>
                              {currentProperties.map((item) => {
                                    const area = item.propertyType === "Plot" || item.propertyType === "Agricultural Land"
                                    ? calculateArea(item.width, item.length)
                                    : null;
                                console.log(item);
                                return (
                                  <div
                                    key={item.id}
                                    className="col-xl-6 col-sm-6 col-12"
                                  >
                                    <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---" >
                                      <div className="product-img go-top">
                                        <Link
                                          to={`/product-details/${item.id}`}
                                        >
                                          {item.propertyImages &&
                                            item.propertyImages.length > 0 ? (
                                            <img
                                              src={`https://way2landmark20240705114540.azurewebsites.net/uploads/${item.propertyImages[0].imageName}`}
                                              alt="#"
                                            />
                                          ) : (
                                            <img
                                              src={dummyProperty}
                                              alt="Alternative Image"
                                            />
                                          )}
                                        </Link>
                                      </div>
                                      <div className="product-info">
                                        <div className="product-badge">
                                          <ul>
                                            <li className="sale-badg">
                                              For {item.proprtyState}
                                            </li>
                                          </ul>
                                        </div>
                                        <h2 className="product-title go-top">
                                          <Link
                                            to={`/product-details/${item.id}`}
                                          >
                                            {item.title}
                                          </Link>
                                        </h2>
                                        <div className="product-img-location go-top">
                                          <ul>
                                            <li>
                                              <Link to="">
                                                <i className="flaticon-pin" />{" "}
                                                {item.cityName}, {item.subLocation}
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                          {item &&
                                            item.propertyType === "Plot" ||
                                            item.propertyType === "Agricultural Land" ? (
                                            undefined
                                          ) :
                                            <>
                                              <li>
                                                <span>{item.bhk} </span> Bed
                                              </li>
                                              <li>
                                                <span>{item.bathrooms} </span> Bath
                                              </li>
                                            </>
                                          }
                                          <li>
                                            category :
                                            <span>{item.category} </span>
                                          </li>
                                          <li>
                                            Property Type :
                                            <span>{item.propertyType} </span>
                                          </li>
                                          <li>
                                            Facing :
                                            <span>{item.facing} </span>
                                          </li>
                                        </ul>

{/* ******************************************************************************************************** */}

{item.totalArea ? (
                            <span style={{ display: "block" }}>
                              Total Area: <span style={{ color: "#198754", fontSize: "16px" }}>{item.totalArea}</span>
                            </span>
                          ) : (
                            <span style={{ display: "block" }}>
                              Total Area: <span style={{ color: "#198754", fontSize: "16px" }}>{area}</span>
                            </span>
                          )
                        }
{(
                        item.propertyType === "Apartment" ||
                        item.propertyType === "Flat" ||
                        item.propertyType === "Independent House" ||
                        item.propertyType === "Villa"
                      ) && (
                          <ul className="ltn__list-item-2 ltn__list-item-2-before">
                            <li>
                              <span>
                                {item.bhk} <i className="flaticon-bed" />
                              </span>
                              Bedrooms
                            </li>
                            <li>
                              <span>
                                {item.bathrooms} <i className="flaticon-clean" />
                              </span>
                              Bathrooms
                            </li>
                          </ul>
                        )}
                      {(
                        item.propertyType === "Plot" ||
                        item.propertyType === "Agricultural Land"
                      ) && (
                          <ul className="ltn__list-item-2 ltn__list-item-2-before">
                            <li>
                              <span>
                                {item.width}
                              </span>
                              Width
                            </li>
                            <li>
                              <span>
                                {item.length}
                              </span>
                              Length
                            </li>
                          </ul>
                        )}


{/* ****************************************************************************************************** */}
                                        {/* <div className="product-hover-action">
                                        <ul>
                                          <li>
                                            <a title="Wishlist">
                                              <i className="flaticon-heart-1" />
                                            </a>
                                          </li>
                                          <li className="go-top">
                                            <Link
                                              to={`/product-details/${item.id}`}
                                              title="Product Details"
                                            >
                                              <i className="flaticon-add" />
                                            </Link>
                                          </li>
                                        </ul>
                                      </div> */}
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="contact-details">
                                            <h6>
                                              <BsFillTelephoneForwardFill className="phone-ring" />{" "}
                                              +91 6303816633
                                            </h6>
                                          </div>
                                          <div className="product-hover-action">
                                            <ul>
                                              <li>
                                                <a
                                                  onClick={() =>
                                                    toggleLike(item.id)
                                                  }
                                                  title="Wishlist"
                                                >
                                                  {likedItemIds.includes(
                                                    item.id
                                                  ) ? (
                                                    <FaHeart className="red-heart" />
                                                  ) : (
                                                    <i className="flaticon-heart-1" />
                                                  )}
                                                </a>
                                              </li>
                                              <li>
                                                <span className="go-top">
                                                  <Link
                                                    to={`/product-details/${item.id}`}
                                                    title="Product Details"
                                                  >
                                                    <i className="flaticon-add" />
                                                  </Link>
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="product-info-bottom">
                                        {/* <div className="product-price">
                                          <span>₹{item.propertyPrice}</span>
                                        </div> */}
                                         <div className="d-flex justify-content-between" style={{width:"100%"
                                         }}>
                        {item.sqftPrice && (
                          <div className="product-price">
                            <span>{item.sqftPrice.replace(' ', '/')}</span>
                          </div>
                        )}
                        <div className="product-price">
                          <span>₹{item.propertyPrice}</span>
                        </div>
                      </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                        {showSearchData && searchData.length === 0 && (
                          <div className="col-12">
                            <button
                              className="btn btn-primary"
                              onClick={handleSeeAllProperties}
                            >
                              See All Properties
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* ***************** List View Of Items **************************** */}
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      {currentProperties.map((item, index) => {
                        console.log(item);
                        return (
                          <div key={index} className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                              <div className="product-img go-top">
                                <Link to={`/product-details/${item.id}`}>
                                  {item.propertyImages &&
                                    item.propertyImages.length > 0 ? (
                                    <img
                                      src={`https://way2landmark20240705114540.azurewebsites.net/uploads/${item.propertyImages[0].imageName}`}
                                      alt="#"
                                    />
                                  ) : (
                                    <img
                                      src={dummyProperty}
                                      alt="Alternative Image"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div className="product-info">
                                <div className="product-badge-price">
                                  <div className="product-badge">
                                    <ul>
                                      <li className="sale-badg">
                                        For {item.proprtyState}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="product-price">
                                    <span>
                                      ₹{item.propertyPrice}
                                      {/* <label>/Month</label> */}
                                    </span>
                                  </div>
                                </div>
                                <h2 className="product-title go-top">
                                  {item.title}
                                </h2>
                                <div className="product-img-location go-top">
                                  <ul>
                                    <li>
                                      <i className="flaticon-pin" /> {item.cityName}{" "}
                                      ,{item.subLocation}
                                    </li>
                                  </ul>
                                </div>
                                <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                  <li>
                                    <span>{item.bhk} </span> Bed
                                  </li>
                                  <li>
                                    <span>{item.bathrooms} </span> Bath
                                  </li>
                                  <li>
                                    category :-
                                    <span>{item.category} </span>
                                  </li>
                                  <li>
                                    Property Type :-
                                    <span>{item.propertyType} </span>
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="d-flex justify-content-between align-items-center"
                                style={{ paddingLeft: "30px" }}
                              >
                                <div className="contact-details">
                                  <h6>
                                    <BsFillTelephoneForwardFill className="phone-ring" />{" "}
                                    +91 6303816633
                                  </h6>
                                </div>
                                <div className="product-hover-action">
                                  <ul>
                                    <li>
                                      <a
                                        onClick={() => toggleLike(item.id)}
                                        title="Wishlist"
                                      >
                                        {likedItemIds.includes(item.id) ? (
                                          <FaHeart className="red-heart" />
                                        ) : (
                                          <i className="flaticon-heart-1" />
                                        )}
                                      </a>
                                    </li>
                                    <li>
                                      <span className="go-top">
                                        <Link
                                          to={`/product-details/${item.id}`}
                                          title="Product Details"
                                        >
                                          <i className="flaticon-add" />
                                        </Link>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ltn__pagination-area text-center">
                <div className="pagination-container">
                  <Pagination>{paginationItems}</Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridV1;
