import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Social from "../section-components/social";
import { UserContext } from "../../utils/Auth";

const Navbar = () => {
  const { userLogin } = useContext(UserContext);

  const history = useHistory();
  const { logoutUser } = useContext(UserContext);

  const handlelogout = () => {
    logoutUser();
    history.push("/");
    window.location.reload();
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-top-area section-bg-6 top-area-color-white---">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you">
                        <i className="icon-mail" /> WaytoLandmark@webmail.com
                      </a>
                    </li>
                    <li>
                      <a href="locations.html">
                        <i className="icon-placeholder" /> way2landmark realtors
                        Pvt Ltd,opposite to hosanna mandir, Rayapudi,522237
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li className="d-none">
                        {/* ltn__language-menu */}
                        <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                          <ul>
                            <li>
                              <a href="#" className="dropdown-toggle">
                                <span className="active-currency">English</span>
                              </a>
                              <ul>
                                <li>
                                  <Link to="#">Arabic</Link>
                                </li>
                                <li>
                                  <Link to="#">Bengali</Link>
                                </li>
                                <li>
                                  <Link to="#">Chinese</Link>
                                </li>
                                <li>
                                  <Link to="#">English</Link>
                                </li>
                                <li>
                                  <Link to="#">French</Link>
                                </li>
                                <li>
                                  <Link to="#">Hindi</Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Social />
                      </li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo go-top">
                    <Link to="/">
                      <img
                        src={publicUrl + "assets/img/wayLogo2.png"}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="get-support clearfix d-none">
                    <div className="get-support-icon">
                      <i className="icon-call" />
                    </div>
                    <div className="get-support-info">
                      <h6>Get Support</h6>
                      <h4>
                        <a href="tel:+123456789">123-456-789-10</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu go-top">
                      <ul>
                        <li className="menu-icon">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-icon">
                          <Link to="/about">About</Link>
                        </li>
                        {/* <li className="menu-icon"><Link to="/shop">Properties</Link>
												</li> */}
                        {/* <li className="menu-icon"><Link to="/blog">blogs</Link>
												</li> */}
                        <li className="menu-icon">
                          <Link to="/service">Services</Link>
                        </li>
                        <li className="menu-icon">
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
                {/* header-search-1 */}
                <div className="header-search-wrap">
                  <div className="header-search-1">
                    <div className="search-icon">
                      <i className="icon-search for-search-show" />
                      <i className="icon-cancel  for-search-close" />
                    </div>
                  </div>
                  <div className="header-search-1-form">
                    <form id="#" method="get" action="#">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search here..."
                      />
                      <button type="submit">
                        <span>
                          <i className="icon-search" />
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
                {/* user-menu */}
                <div className="ltn__drop-menu user-menu">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="icon-user" />
                      </Link>
                      <ul className="go-top">
                        {userLogin ? (
                          <>
                            <li>
                              <Link to="/add-listing">Add Listing</Link>
                            </li>
                            <li>
                              <Link to="/my-account">My Account</Link>
                            </li>
                            <li onClick={handlelogout}>
                              <Link>Logout</Link>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link to="/login">Sign in</Link>
                            </li>
                            <li>
                              <Link to="/register">Register</Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>
                {/* mini-cart */}
                {/* mini-cart */}
                {/* Mobile Menu Button */}
                <div className="mobile-menu-toggle d-xl-none">
                  <a className="ltn__utilize-toggle" onClick={toggleMenu}>
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        id="ltn__utilize-mobile-menu"
        className={`ltn__utilize ${isOpen ? "ltn__utilize-open" : ""}`}
      >
        {/* <div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu"> */}
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <Link to="/">
                <img src={publicUrl + "assets/img/wayLogo2.png"} alt="Logo" />
              </Link>
            </div>
            <button onClick={closeMenu} className="ltn__utilize-close">
              ×
            </button>
          </div>
          <div className="ltn__utilize-menu-search-form">
            <form action={"#"}>
              <input type="text" placeholder="Search..." />
              <button>
                <i className="fas fa-search" />
              </button>
            </form>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              {/* <li ><Link to="/shop">Properties</Link>
							</li> */}
              {/* <li ><Link to="/blog">blogs</Link>
							</li> */}
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              {userLogin ? (
                <>
                  <li>
                    <Link to="/my-account">My Account</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/add-listing">Add Listing</Link>
                      </li>
                      <li onClick={handlelogout}>
                        <Link>Logout</Link>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/login">Sign in</Link>
                      </li>
                      <li>
                        <Link to="/register">Register</Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="ltn__utilize-overlay" onClick={toggleMenu}></div>
      )}
    </div>
  );
};

export default Navbar;
