import React, { useEffect, useState } from "react";
import Navbar from "./global-components/navbar-v4";
import BannerV6 from "./section-components/banner-v6";
import Aboutv2 from "./section-components/about-v2";
import Featuresv1 from "./section-components/features-v1";
import ProSlider from "./section-components/product-slider-v4";
import BlogSlider from "./blog-components/blog-slider-v1";
import Gallery from "./section-components/gallery-v1";
import Apartment from "./section-components/apartment-v1";
import VideoV1 from "./section-components/video-v1";
import Category from "./section-components/category-v1";
import Testimonial from "./section-components/testimonial-v1";
import CallToActionV1 from "./section-components/call-to-action-v1";
import Footer from "./global-components/footer";
import CounterV1 from "./section-components/counter-v1";
import Servicev1 from "../components/section-components/service-v2";
import ProductSliderV4 from "./section-components/product-slider-v4";
import ServiceV5 from "./section-components/service-v1";
import Instence from "../utils/Instence";

const HomeScreen = () => {
  
  const [visitCount, setVisitCount] = useState(0);

 const PostVisitCount = async () => {
  console.log("PostVisitCount function called");
    try {
      const response = await Instence.post("Blogs/increment-view-count", {},  {
        headers: {
          'Content-Type': 'application/json',
        },});
      if (response.status === 200) {
        console.log("Count API hit successfully");
        console.log(response.data);
        setVisitCount(response.data.viewCount1); 
      }
    } catch (error) {
      console.error("Error while hitting the count API:", error);
    }
  };



  useEffect(() => {
    console.log("useEffect called");
    const hasCounted = sessionStorage.getItem("hasCounted");
    console.log("Has counted:", hasCounted);
    if (!hasCounted) {
      console.log("API will be called now");
      PostVisitCount().then(() => {
        sessionStorage.setItem("hasCounted", "true");
      });
    }else {
      console.log("API call skipped as it is already counted this session"); // Debugging log
    }
    
  }, []);


  

  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      <BannerV6 />
      <Servicev1 />
      <ProSlider />
      <CounterV1 />
      <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      {/* <Aboutv2 /> */}
      <VideoV1 />
      <ServiceV5 />
      {/* <Category /> */}
      <Testimonial />
      {/* <BlogSlider customClass="section-subtitle-2" /> */}
      <CallToActionV1 />
      {/* <h3>Total Visits: {visitCount}</h3> */}
      <Footer />
    </div>
  );
};

export default HomeScreen;
