import React, { Component, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import Instence from "../../utils/Instence";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { registrationSchema } from "../../utils/YupSchemas";
import { UserContext } from "../../utils/Auth";
import { SpinnerComponent } from "./add-listing";

const Register = () => {
  // let publicUrl = process.env.PUBLIC_URL + '/'
  const { loginUser } = useContext(UserContext);
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  // ! Handle Register Form Validation

  const handleRegister = async (e) => {
    console.log("Api Hit")
    e.preventDefault();
    setLoading(true); 
    try {
      await registrationSchema.validate(
        { firstName, lastName, email, password, confirmPassword },
        { abortEarly: false }
      );

      //? Check if passwords match

      if (password !== confirmPassword) {
        Swal.fire({
          title: "Error!",
          text: "Passwords do not match",
          icon: "error",
        });

        // Hide error message after 3 seconds
        setTimeout(() => {
          Swal.close();
        }, 3000);

        return; //* Exit function early if passwords don't match
      }
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('role', 'user');
      formData.append('status', true);

      const response = await Instence.post("/User", formData);
     
      Swal.fire({
        title: "Good job!",
        text: "You Are successfully Registered",
        icon: "success",
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 3000,
      });
      sessionStorage.setItem("userData", JSON.stringify(response.data.statusData));
      if (response.data) {
        loginUser();
        history.push("/");
        window.scrollTo(0, 0);
      }
      setLoading(true);
      loginUser();
      console.log(response.data);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((e) => {
        validationErrors[e.path] = e.message;
      });
      setErrors(validationErrors);


      setTimeout(() => {
        setLoading(false);
      }, 3000);

      // Clear validation errors after 3 seconds
      setTimeout(() => {
        setErrors({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
      }, 3000);
    }
  };

  return (
    <div className="ltn__login-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center">
              <h1 className="section-title">
                Register <br />
                Your Account
              </h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="account-login-inner">
              <div>
                {errors.firstName && (
                  <div className="error-message">{errors.firstName}</div>
                )}
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  name="firstname"
                  placeholder="First Name"
                  pattern="[A-Za-z]*"
                  onInput={(e) => {
                    if (!/^[A-Za-z]*$/.test(e.target.value)) {
                      e.target.value = e.target.value.slice(0, -1);
                    }
                  }}
                />
                {errors.lastName && (
                  <div className="error-message">{errors.lastName}</div>
                )}
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  name="lastname"
                  placeholder="Last Name"
                  pattern="[A-Za-z]*"
                  onInput={(e) => {
                    if (!/^[A-Za-z]*$/.test(e.target.value)) {
                      e.target.value = e.target.value.slice(0, -1);
                    }
                  }}
                />
                {errors.email && (
                  <div className="error-message">{errors.email}</div>
                )}
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  name="email"
                  placeholder="Phone Number *"
                  pattern="[0-9]*"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  maxLength={10}
                />
                {errors.password && (
                  <div className="error-message">{errors.password}</div>
                )}
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password*"
                />
                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={handleTogglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
                {errors.confirmPassword && (
                  <div className="error-message">{errors.confirmPassword}</div>
                )}
                <input
                  value={confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  name="confirmpassword"
                  placeholder="Confirm Password*"
                />
                <button
                  type="button"
                  className="password-toggle-btn"
                  onClick={handleToggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEye : faEyeSlash}
                  />
                </button>

                <label className="checkbox-inline">
                  <input type="checkbox" defaultValue />
                  &nbsp; I consent to Herboil processing my personal data in
                  order to send personalized marketing material in accordance
                  with the consent form and the privacy policy.
                </label>
                <label className="checkbox-inline">
                  <input type="checkbox" defaultValue /> &nbsp; By clicking
                  "create account", I consent to the privacy policy.
                </label>
                <div className="btn-wrapper btn_unwrap">
                  <button
                    className="theme-btn-1 btn reverse-color btn-block"
                    onClick={handleRegister}
                  >
                    {loading ? <SpinnerComponent /> : "CREATE ACCOUNT"}
                  </button>
                </div>
              </div>
              <div className="by-agree text-center">
                <p>By creating an account, you agree to our:</p>
                <p>
                  <a href="#">
                    TERMS OF CONDITIONS &nbsp; &nbsp; | &nbsp; &nbsp; PRIVACY
                    POLICY
                  </a>
                </p>
                <div className="go-to-btn mt-50 go-top">
                  <Link to="/login">ALREADY HAVE AN ACCOUNT ?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
