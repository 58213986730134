import React, { useEffect, useState } from "react";
import Instence from "../../utils/Instence";
import Swal from "sweetalert2";
import { Listingschema } from "../../utils/YupSchemas";
import Data from "../../Data";
import "../../Css/Global.css";
import { FaTrashAlt } from "react-icons/fa";

export const SpinnerComponent = () => {
  return (
    <div className="spinner_1"></div> // Add appropriate CSS class for styling
  );
};

const AddListing = () => {
  const [loading, setLoading] = useState(false);

  const [category, setCategory] = useState("");
  const [showSellDetails, setShowSellDetails] = useState(false);
  const [showRentDetails, setShowRentDetails] = useState(false);
  const [propertyType, setPropertyType] = useState("");
  const [Facing, setFacing] = useState("");
  const [GpsLocationLink, setGpsLocationLink] = useState("");

  console.log(propertyType);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [subLocation, setSubLocation] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState(null);
  const [propertyState, setPropertyState] = useState("");
  const [processionStatus, setProcessionStatus] = useState("");
  const [plotPrice, setPlotPrice] = useState(null);
  const [sqftPrice, setSqftPrice] = useState(null);
  const [maintenanceCharge, setMaintenanceCharge] = useState(null);
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [TotalArea, setTotalArea] = useState("");
  const [widthOfFacingRoad, setWidthOfFacingRoad] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [bhk, setBhk] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [furnishType, setFurnishType] = useState("");
  const [covered2WheelParking, setCovered2WheelParking] = useState("");
  const [covered4WheelParking, setCovered4WheelParking] = useState("");
  const [open2WheelParking, setOpen2WheelParking] = useState("");
  const [open4WheelParking, setOpen4WheelParking] = useState("");
  const [builtupArea, setBuiltupArea] = useState("");
  const [carpetArea, setCarpetArea] = useState("");



// ********************* Data Mapping for Locations, Sub Locations ***************************

const [Data, setData] = useState([]);

const GetLocationsData = async () => {
  try {
    const response = await Instence.get("Location");
    if (response.status === 200) {
      setData(response.data);
      console.log("Fetched Locations Data", response.data);
    }
  } catch (error) {
    console.log(error);
  }
};

useEffect(() => {
  GetLocationsData();
}, []);

console.log("Filtered Data", Data);

const filteredAreas = city
  ? Data.find((item) => item.id === parseInt(city))?.areas || []
  : [];


console.log("Filtered Areas:", filteredAreas);



// ********************************************************************


  // const filteredAreas = city
  //   ? Data.city.find((item) => item.name === city)?.areas || []
  //   : [];

  //! States for units
  const [plotPriceUnit, setPlotPriceUnit] = useState("K");
  const [maintenanceChargeUnit, setMaintenanceChargeUnit] = useState("K");
  const [builtupAreaUnit, setBuiltupAreaUnit] = useState("sqft");
  const [carpetAreaUnit, setCarpetAreaUnit] = useState("sqft");
  //! States for units
  const [widthUnit, setWidthUnit] = useState("sqft");
  const [lengthUnit, setLengthUnit] = useState("sqft");
  const [totalAreaUnit, setTotalAreaUnit] = useState("sqft");
  const [roadWidthUnit, setRoadWidthUnit] = useState("sqft");
  const [perSqfthUnit, setperSqfthUnit] = useState("sqft");

  //! userId getting from local storage

  const userId = JSON.parse(sessionStorage.getItem("userData"));
  const id = userId && userId.id;
  console.log(id);

  //! handle changes for properties state
  const handlepropertyStateChange = (event) => {
    const selectedpropertyState = event.target.value;
    setPropertyState(selectedpropertyState);
    setShowSellDetails(selectedpropertyState === "Sell");
    setShowRentDetails(selectedpropertyState === "Rent");
  };

  const handlePropertyTypeChange = (event) => {
    const selectedPropertyType = event.target.value;
    setPropertyType(selectedPropertyType);
  };

  // const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState([]);
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Mapping over the selected files to create an array of objects containing file and URL
    const newImages = selectedFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file)
    }));

    // Updating images state using functional form of setState
    setImages((prevImages) => [...prevImages, ...newImages]);
  };
  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  // const renderImagePreviews = () => {
  //   return selectedFiles.map((file, index) => (
  //     <img
  //       key={index}
  //       src={URL.createObjectURL(file)}
  //       alt={`Preview ${index + 1}`}
  //       className="preview-image"
  //     />
  //   ));
  // };

  //! handle submit functionality
  const [errors, setErrors] = useState([]);

  // const handleInputChange = (fieldName) => {
  //   // Clear the error message for the corresponding field
  //   setErrors((prevErrors) => {
  //     return {
  //       ...prevErrors,
  //       [fieldName]: "",
  //     };
  //   });
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await Listingschema.validate(
        {
          category,
          propertyType,
          propertyState,
          title,
          description,
          address,
          city,
          location,
          subLocation,
          pincode,
          builtupArea,
          carpetArea,
          // GpsLocationLink,
          processionStatus,
          // furnishType,
          Facing,
          plotPrice,
          maintenanceCharge,
        },
        { abortEarly: false }
      );

      const formData = new FormData();
      const plotPriceWithUnit = `${plotPrice} ${plotPriceUnit}`;
      const maintancewithunit = `${maintenanceCharge} ${maintenanceChargeUnit}`;
      const widthWithUnit = `${width} ${widthUnit}`;
      const lengthWithUnit = `${length} ${lengthUnit}`;
      const totalAreaWithUnit = `${TotalArea} ${totalAreaUnit}`;
      const widthOfFacingRoadWithUnit = `${widthOfFacingRoad} ${roadWidthUnit}`;
      const carpetAreaWithUnit = `${carpetArea} ${carpetAreaUnit}`;
      const builtupAreaWithUnit = `${builtupArea} ${builtupAreaUnit}`;
      const perSqftWithUnit = `${sqftPrice} ${perSqfthUnit}`;

      formData.append("userId", id);
      formData.append("title", title);
      formData.append("propertyType", propertyType);
      formData.append("description", description);
      formData.append("category", category);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("sqftPrice", perSqftWithUnit);
      formData.append("location", location);
      formData.append("subLocation", subLocation);
      formData.append("landmark", landmark);
      formData.append("pincode", pincode);
      formData.append("proprtyState", propertyState);
      formData.append("processionStatus", processionStatus);
      formData.append("propertyPrice", plotPriceWithUnit);
      formData.append("maintanenceCharge", maintancewithunit);
      formData.append("width", widthWithUnit);
      formData.append("length", lengthWithUnit);
      formData.append("TotalArea", totalAreaWithUnit);
      formData.append("widthOfFacingRoad", widthOfFacingRoadWithUnit);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("bhk", bhk);
      formData.append("bathrooms", bathrooms);
      formData.append("furnishType", furnishType);
      formData.append("covered2WheelParking", covered2WheelParking);
      formData.append("covered4WheelParking", covered4WheelParking);
      formData.append("open2WheelParking", open2WheelParking);
      formData.append("open4WheelParking", open4WheelParking);
      formData.append("builtupArea", builtupAreaWithUnit);
      formData.append("carpetArea", carpetAreaWithUnit);
      formData.append("facing", Facing);
      formData.append("gpslocation", GpsLocationLink);
      // Append selected images
      images.forEach((file, index) => {
        formData.append(`images`, file.file);
      });
      const response = await Instence.post("/Property/AddListing", formData);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Property added successfully!",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 3000,
        });
        setLoading(false);
        setTitle("");
        setPropertyType("");
        setDescription("");
        setCategory("");
        setAddress("");
        setCity("");
        setLocation("");
        setSubLocation("");
        setLandmark("");
        setPincode("");
        setPropertyState("");
        setProcessionStatus("");
        setPlotPrice("");
        setPlotPriceUnit("");
        setMaintenanceCharge("");
        setMaintenanceChargeUnit("");
        setWidth("");
        setWidthUnit("");
        setLength("");
        setLengthUnit("");
        setTotalArea("");
        setTotalAreaUnit("");
        setWidthOfFacingRoad("");
        setRoadWidthUnit("");
        setLatitude("");
        setLongitude("");
        setBhk("");
        setBathrooms("");
        setFurnishType("");
        setCovered2WheelParking("");
        setCovered4WheelParking("");
        setOpen2WheelParking("");
        setOpen4WheelParking("");
        setBuiltupArea("");
        setBuiltupAreaUnit("");
        setCarpetArea("");
        setCarpetAreaUnit("");
        setFacing("");
        setGpsLocationLink("");
        document.getElementById("file").value = "";
        setImages([]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to add property. Please try again." + response.title,
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      console.log(response.data);
    } catch (error) {
      // Handle validation errors
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
        // Setting loading to false after 3 seconds
        setTimeout(() => {
          setLoading(false);
        }, 3000);
        // Swal.fire({
        //   icon: "error",
        //   title: "Validation Error",
        //   text: "Please check the form for errors.",
        //   timerProgressBar: true,
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
        // Clear errors after 5 seconds
        // setTimeout(() => {
        //   setErrors({});
        // }, 6000);
      } else {
        setErrors({ general: error.response.data.title });
        // Clear errors after 5 seconds
        setTimeout(() => {
          setErrors({});
        }, 5000);
        console.log(error.response);
      }
    }
  };

  return (
    <div className="ltn__appointment-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <form onSubmit={handleSubmit}>
                <h2>1. Description</h2>
                <p>
                  <small>
                    These fields are mandatory: Title, Property Media
                  </small>
                </p>
                <h6>Property Description</h6>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {errors.title && (
                        <div className="error-message">{errors.title}</div>
                      )}
                      <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        // onChange={(e) => { setTitle(e.target.value); handleInputChange("title") }}
                        type="text"
                        name="ltn__name"
                        placeholder="*Title (mandatory)"
                      />
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      {errors.description && (
                        <div className="error-message">
                          {errors.description}
                        </div>
                      )}
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        name="ltn__message"
                        placeholder="Description"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <h6>Select Categories</h6>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="input-item">
                      {errors.category && (
                        <div className="error-message">{errors.category}</div>
                      )}
                      <select
                        className="nice-select"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <option selected value>
                          I want to
                        </option>
                        <option value="Residential">Residential</option>
                        <option value="Commercial">Commercial</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-item">
                      {errors.propertyState && (
                        <div className="error-message">
                          {errors.propertyState}
                        </div>
                      )}
                      <select
                        className="nice-select"
                        value={propertyState}
                        onChange={handlepropertyStateChange}
                      >
                        <option disabled selected value="">
                          I want to
                        </option>
                        <option value="Sell">Sell</option>
                        <option value="Rent">Rentals</option>
                      </select>
                    </div>
                  </div>
                </div>
                {showSellDetails && (
                  <>
                    <h4>Sell</h4>
                    <h6>Listing Details</h6>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="input-item">
                          {errors.propertyType && (
                            <div className="error-message">
                              {errors.propertyType}
                            </div>
                          )}
                          <select
                            className="nice-select"
                            value={propertyType}
                            onChange={handlePropertyTypeChange}
                          >
                            <option disabled value="">
                              Property Type
                            </option>
                            <option value="Apartment">Apartment</option>
                            <option value="Flat">Flat</option>
                            <option value="Independent House">
                              Independent House
                            </option>
                            <option value="Villa">Villa</option>
                            <option value="Plot">Open Plot</option>
                            <option value="Agricultural Land">
                              Agricultural Land
                            </option>
                          </select>
                        </div>
                      </div>
                      {propertyType === "Apartment" ||
                        propertyType === "Flat" ||
                        propertyType === "Independent House" ||
                        propertyType === "Villa" ? (
                        <>
                          <div className="col-md-6">
                            <div className="input-item">
                              {errors.processionStatus && (
                                <div className="error-message">
                                  {errors.processionStatus}
                                </div>
                              )}
                              <select
                                value={processionStatus}
                                onChange={(e) =>
                                  setProcessionStatus(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value="">select Property Status</option>
                                <option value="ready to move">
                                  ready to move
                                </option>
                                <option value="Under Construction">
                                  Under Construction
                                </option>
                              </select>
                            </div>
                          </div>
                          {/* Existing code for other fields */}
                          <div className="col-md-6">
                            <div className="input-item">
                              <select
                                value={bhk}
                                onChange={(e) => setBhk(e.target.value)}
                                className="nice-select"
                              >
                                <option value=""> Select BHK</option>
                                <option value="1Rk">1RK</option>
                                <option value="1BHK">1BHK</option>
                                <option value="2BHK">2BHK</option>
                                <option value="3BHK">3BHK</option>
                                <option value="3+BHK">3+BHK</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item">
                              <select
                                value={bathrooms}
                                onChange={(e) =>
                                  setBathrooms(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value=""> Select Bathrooms</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="3+">3+</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item">
                              {/* {errors.furnishType && (
                                <div className="error-message">
                                  {errors.furnishType}
                                </div>
                              )} */}
                              <select
                                value={furnishType}
                                onChange={(e) => setFurnishType(e.target.value)}
                                className="nice-select"
                              >
                                <option disabled value="">
                                  {" "}
                                  Select Furnish Type
                                </option>
                                <option value="Furnished">Furnished</option>
                                <option value="Semi-Furnished">
                                  Semi-Furnished
                                </option>
                                <option value="Unfurnished">Unfurnished</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="input-item">
                              {errors.Facing && (
                                <div className="error-message">
                                  {errors.Facing}
                                </div>
                              )}
                              <select
                                className="nice-select"
                                value={Facing}
                                onChange={(e) => setFacing(e.target.value)}
                              >
                                <option value="">Select Facing</option>
                                <option value="East">East</option>
                                <option value="West">West</option>
                                <option value="South">South</option>
                                <option value="North">North</option>
                                <option value="North East">North East</option>
                                <option value="South East">South East</option>
                                <option value="North West">North West</option>
                                <option value="South West">South West</option>
                                <option value="East West">East West</option>
                                <option value="North South">North South</option>
                              </select>
                            </div>
                          </div>
                          <h6>Covered Parking</h6>
                          <div className="col-md-6">
                            <div className="input-item">
                              <select
                                value={covered2WheelParking}
                                onChange={(e) =>
                                  setCovered2WheelParking(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value="">
                                  Select 2 Wheelers Parking
                                </option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item">
                              <select
                                value={covered4WheelParking}
                                onChange={(e) =>
                                  setCovered4WheelParking(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value="">
                                  Select 4 Wheelers Parking
                                </option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                            </div>
                          </div>
                          <h6>Open Parking</h6>
                          <div className="col-md-6">
                            <div className="input-item">
                              <select
                                value={open2WheelParking}
                                onChange={(e) =>
                                  setOpen2WheelParking(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value="">
                                  Select 2 Wheelers Parking
                                </option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item">
                              <select
                                value={open4WheelParking}
                                onChange={(e) =>
                                  setOpen4WheelParking(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value="">
                                  Select 4 Wheelers Parking
                                </option>
                                <option value="Not Available">
                                  Not Available
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select>
                            </div>
                          </div>
                          <h6>Property Price</h6>
                          <div className="col-md-6">
                            {errors.plotPrice && (
                              <div className="error-message">
                                {errors.plotPrice}
                              </div>
                            )}
                            <div className="input-item input-item-textarea ">
                              <input
                                value={plotPrice}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-numeric or non-decimal point character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setPlotPrice(sanitizedInput);
                                }}
                                type="text"
                                name="ltn__name"
                                placeholder="Cost ₹ (*only numbers)"
                              />
                              <select
                                value={plotPriceUnit}
                                name="unit"
                                onChange={(e) =>
                                  setPlotPriceUnit(e.target.value)
                                }
                              >
                                <option value="K">Thousands (k)</option>
                                <option value="L">Lakhs (l)</option>
                                <option value="Cr">Crores (cr)</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {errors.maintenanceCharge && (
                              <div className="error-message">
                                {errors.maintenanceCharge}
                              </div>
                            )}
                            <div className="input-item input-item-textarea">
                              <input
                                value={maintenanceCharge}
                                // onChange={(e) =>
                                //   setMaintenanceCharge(e.target.value)
                                // }
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setMaintenanceCharge(sanitizedInput);
                                }}
                                type="text"
                                name="ltn__name"
                                placeholder="Maintenance Charges (per month)"

                              />
                              <select
                                value={maintenanceChargeUnit}
                                name="unit"
                                onChange={(e) =>
                                  setMaintenanceChargeUnit(e.target.value)
                                }
                              >
                                <option value="K">Thousands (k)</option>
                                <option value="L">Lakhs (l)</option>
                                <option value="Cr">Crores (cr)</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Built Up Area"
                                value={builtupArea}
                                // onChange={(e) => setBuiltupArea(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setBuiltupArea(sanitizedInput);
                                }}
                              // className="NumberInput"
                              />
                              <select
                                value={builtupAreaUnit}
                                name="unit"
                                onChange={(e) =>
                                  setBuiltupAreaUnit(e.target.value)
                                }
                              >
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="sqyd">
                                  Square Yards (sqyd)
                                </option>
                                <option value="sqin">
                                  Square Inches (sqin)
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Carpet Area"
                                value={carpetArea}
                                // onChange={(e) => setCarpetArea(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setCarpetArea(sanitizedInput);
                                }}
                              // className="NumberInput"
                              />
                              <select
                                value={carpetAreaUnit}
                                name="unit"
                                onChange={(e) =>
                                  setCarpetAreaUnit(e.target.value)
                                }
                              >
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="sqyd">
                                  Square Yards (sqyd)
                                </option>
                                <option value="sqin">
                                  Square Inches (sqin)
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Price per units (Ex :- 2000/Sqft)"
                                value={sqftPrice}
                                // onChange={(e) => setCarpetArea(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setSqftPrice(sanitizedInput);
                                }}
                              // className="NumberInput"
                              />
                              <select
                                value={perSqfthUnit}
                                name="unit"
                                onChange={(e) =>
                                  setperSqfthUnit(e.target.value)
                                }
                              >
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="sqyd">
                                  Square Yards (sqyd)
                                </option>
                                <option value="sqin">
                                  Square Inches (sqin)
                                </option>
                              </select>
                            </div>
                          </div>
                        </>
                      ) : propertyType === "Plot" ||
                        propertyType === "Agricultural Land" ? (
                        <>
                          <div className="col-md-6">
                            <div className="input-item">
                              {errors.processionStatus && (
                                <div className="error-message">
                                  {errors.processionStatus}
                                </div>
                              )}
                              <select
                                value={processionStatus}
                                onChange={(e) =>
                                  setProcessionStatus(e.target.value)
                                }
                                className="nice-select"
                              >
                                <option value="">select Property Status</option>
                                <option value="Immediate">Immediate</option>
                                <option value="In Future">In Future</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {errors.plotPrice && (
                              <div className="error-message">
                                {errors.plotPrice}
                              </div>
                            )}
                            <div className="input-item input-item-textarea ">
                              <input
                                value={plotPrice}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-numeric or non-decimal point character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setPlotPrice(sanitizedInput);
                                }}
                                type="text"
                                name="ltn__name"
                                placeholder="Cost ₹ (*only numbers)"
                              />
                              <select
                                value={plotPriceUnit}
                                name="unit"
                                onChange={(e) =>
                                  setPlotPriceUnit(e.target.value)
                                }
                              >
                                <option value="K">Thousands (k)</option>
                                <option value="L">Lakhs (l)</option>
                                <option value="Cr">Crores (cr)</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {errors.maintenanceCharge && (
                              <div className="error-message">
                                {errors.maintenanceCharge}
                              </div>
                            )}
                            <div className="input-item input-item-textarea ">
                              <input
                                value={maintenanceCharge}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setMaintenanceCharge(sanitizedInput);
                                }}
                                type="text"
                                name="ltn__name"
                                placeholder="Maintenance Charges (per month)"
                              />
                              <select
                                value={maintenanceChargeUnit}
                                name="unit"
                                onChange={(e) =>
                                  setMaintenanceChargeUnit(e.target.value)
                                }
                              >
                                <option value="K">Thousands (k)</option>
                                <option value="L">Lakhs (l)</option>
                                <option value="Cr">Crores (cr)</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Price per units (Ex :- 2000/Sqft)"
                                value={sqftPrice}
                                // onChange={(e) => setCarpetArea(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setSqftPrice(sanitizedInput);
                                }}
                              // className="NumberInput"
                              />
                              <select
                                value={perSqfthUnit}
                                name="unit"
                                onChange={(e) =>
                                  setperSqfthUnit(e.target.value)
                                }
                              >
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="sqyd">
                                  Square Yards (sqyd)
                                </option>
                                <option value="sqin">
                                  Square Inches (sqin)
                                </option>
                              </select>
                            </div>
                          </div>
                          <h6>Plot Area</h6>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Width"
                                value={width}
                                // onChange={(e) => setWidth(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setWidth(sanitizedInput);
                                }}
                              />
                              <select
                                name="unit"
                                onChange={(e) => setWidthUnit(e.target.value)}
                                value={widthUnit}
                              >
                                <option value="sqyd">Square Yards (sqyd)</option>
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="acres">Acres</option>
                                <option value="hectares">Hectares</option>
                                <option value="guntha">Guntha</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Length "
                                value={length}
                                // onChange={(e) => setLength(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setLength(sanitizedInput);
                                }}
                              />
                              <select
                                name="unit"
                                onChange={(e) => setLengthUnit(e.target.value)}
                                value={lengthUnit}
                              >
                                <option value="sqyd">Square Yards (sqyd)</option>
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="acres">Acres</option>
                                <option value="hectares">Hectares</option>
                                <option value="guntha">Guntha</option>
                              </select>
                            </div>
                          </div>

{/* *************************  Added NEW FIELD "TOTAL AREA" ************************************** */}


<div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Total Area"
                                value={TotalArea}
                                // onChange={(e) => setLength(e.target.value)}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setTotalArea(sanitizedInput);
                                }}
                              />
                              <select
                                name="unit"
                                onChange={(e) => setTotalAreaUnit(e.target.value)}
                                value={totalAreaUnit}
                              >
                                <option value="sqyd">Square Yards (sqyd)</option>
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="acres">Acres</option>
                                <option value="hectares">Hectares</option>
                                <option value="guntha">Guntha</option>
                              </select>
                            </div>
                          </div>






{/* *********************************************************************************************** */}

                          <div className="col-lg-6 col-md-6">
                            {errors.Facing && (
                              <div className="error-message">
                                {errors.Facing}
                              </div>
                            )}
                            <div className="input-item">
                              <select
                                className="nice-select"
                                value={Facing}
                                onChange={(e) => setFacing(e.target.value)}
                              >
                                <option value="">Select Facing</option>
                                <option value="East">East</option>
                                <option value="West">West</option>
                                <option value="South">South</option>
                                <option value="North">North</option>
                                <option value="North East">North East</option>
                                <option value="South East">South East</option>
                                <option value="North West">North West</option>
                                <option value="South West">South West</option>
                                <option value="East West">East West</option>
                                <option value="North South">North South</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-item input-item-textarea ">
                              <input
                                type="text"
                                name="ltn__name"
                                placeholder="Width of facing Road"
                                value={widthOfFacingRoad}
                                // onChange={(e) =>
                                //   setWidthOfFacingRoad(e.target.value)
                                // }
                                onChange={(e) => {
                                  const input = e.target.value;
                                  // Replace any non-digit character with an empty string
                                  const sanitizedInput = input.replace(/[^\d.]/g, '');
                                  // Update state with sanitized input
                                  setWidthOfFacingRoad(sanitizedInput);
                                }}
                              />
                              <select
                                name="unit"
                                onChange={(e) =>
                                  setRoadWidthUnit(e.target.value)
                                }
                                value={roadWidthUnit}
                              >
                                <option value="sqyd">Square Yads (sqyd)</option>
                                <option value="sqft">Square Feet (sqft)</option>
                                <option value="sqm">Square Meters (sqm)</option>
                                <option value="acres">Acres</option>
                                <option value="hectares">Hectares</option>
                                <option value="guntha">Guntha</option>
                              </select>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </>
                )}
                {showRentDetails && (
                  <>
                    <h4>Rent</h4>
                    <h6>Listing Details</h6>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="input-item">
                          {errors.propertyType && (
                            <div className="error-message">
                              {errors.propertyType}
                            </div>
                          )}
                          <select
                            value={propertyType}
                            onChange={handlePropertyTypeChange}
                            className="nice-select"
                          >
                            <option disabled value="">
                              Property Type
                            </option>
                            <option value="Apartment">Apartment</option>
                            <option value="Flat">Flat</option>
                            <option value="Independent House">
                              Independent House
                            </option>
                            <option value="Villa">Villa</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-item">
                          {errors.processionStatus && (
                            <div className="error-message">
                              {errors.processionStatus}
                            </div>
                          )}
                          <select
                            value={processionStatus}
                            onChange={(e) =>
                              setProcessionStatus(e.target.value)
                            }
                            className="nice-select"
                          >
                            <option disabled value="">
                              select Property Status
                            </option>
                            <option value="Apartment">Immediate</option>
                            <option value="Flat">In Future</option>
                          </select>
                        </div>
                      </div>
                      {/* Existing code for other fields */}
                      <div className="col-md-6">
                        <div className="input-item">
                          <select
                            value={bhk}
                            onChange={(e) => setBhk(e.target.value)}
                            className="nice-select"
                          >
                            <option value=""> Select BHK</option>
                            <option value="1Rk">1RK</option>
                            <option value="1BHK">1BHK</option>
                            <option value="2BHK">2BHK</option>
                            <option value="3BHK">3BHK</option>
                            <option value="3+BHK">3+BHK</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select
                            value={bathrooms}
                            onChange={(e) =>
                              setBathrooms(e.target.value)
                            }
                            className="nice-select"
                          >
                            <option value=""> Select Bathrooms</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="3+">3+</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          {/* {errors.furnishType && (
                                <div className="error-message">
                                  {errors.furnishType}
                                </div>
                              )} */}
                          <select
                            value={furnishType}
                            onChange={(e) => setFurnishType(e.target.value)}
                            className="nice-select"
                          >
                            <option disabled value="">
                              {" "}
                              Select Furnish Type
                            </option>
                            <option value="Furnished">Furnished</option>
                            <option value="Semi-Furnished">
                              Semi-Furnished
                            </option>
                            <option value="Unfurnished">Unfurnished</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-item">
                          {errors.Facing && (
                            <div className="error-message">
                              {errors.Facing}
                            </div>
                          )}
                          <select
                            className="nice-select"
                            value={Facing}
                            onChange={(e) => setFacing(e.target.value)}
                          >
                            <option value="">Select Facing</option>
                            <option value="East">East</option>
                            <option value="West">West</option>
                            <option value="South">South</option>
                            <option value="North">North</option>
                            <option value="North East">North East</option>
                            <option value="South East">South East</option>
                            <option value="North West">North West</option>
                            <option value="South West">South West</option>
                            <option value="East West">East West</option>
                            <option value="North South">North South</option>
                          </select>
                        </div>
                      </div>
                      <h6>Covered Parking</h6>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select
                            value={covered2WheelParking}
                            onChange={(e) =>
                              setCovered2WheelParking(e.target.value)
                            }
                            className="nice-select"
                          >
                            <option value="">
                              Select 2 Wheelers Parking
                            </option>
                            <option value="Not Available">
                              Not Available
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select
                            value={covered4WheelParking}
                            onChange={(e) =>
                              setCovered4WheelParking(e.target.value)
                            }
                            className="nice-select"
                          >
                            <option value="">
                              Select 4 Wheelers Parking
                            </option>
                            <option value="Not Available">
                              Not Available
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>
                      <h6>Open Parking</h6>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select
                            value={open2WheelParking}
                            onChange={(e) =>
                              setOpen2WheelParking(e.target.value)
                            }
                            className="nice-select"
                          >
                            <option value="">
                              Select 2 Wheelers Parking
                            </option>
                            <option value="Not Available">
                              Not Available
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item">
                          <select
                            value={open4WheelParking}
                            onChange={(e) =>
                              setOpen4WheelParking(e.target.value)
                            }
                            className="nice-select"
                          >
                            <option value="">
                              Select 4 Wheelers Parking
                            </option>
                            <option value="Not Available">
                              Not Available
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>
                      <h6>Property Price</h6>
                      <div className="col-md-6">
                        {errors.plotPrice && (
                          <div className="error-message">
                            {errors.plotPrice}
                          </div>
                        )}
                        <div className="input-item input-item-textarea ">
                          <input
                            value={plotPrice}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-numeric or non-decimal point character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setPlotPrice(sanitizedInput);
                            }}
                            type="text"
                            name="ltn__name"
                            placeholder="Cost ₹ (*only numbers)"
                          />
                          <select
                            value={plotPriceUnit}
                            name="unit"
                            onChange={(e) =>
                              setPlotPriceUnit(e.target.value)
                            }
                          >
                            <option value="K">Thousands (k)</option>
                            <option value="L">Lakhs (l)</option>
                            <option value="Cr">Crores (cr)</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {errors.maintenanceCharge && (
                          <div className="error-message">
                            {errors.maintenanceCharge}
                          </div>
                        )}
                        <div className="input-item input-item-textarea">
                          <input
                            value={maintenanceCharge}
                            // onChange={(e) =>
                            //   setMaintenanceCharge(e.target.value)
                            // }
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-digit character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setMaintenanceCharge(sanitizedInput);
                            }}
                            type="text"
                            name="ltn__name"
                            placeholder="Maintenance Charges (per month)"

                          />
                          <select
                            value={maintenanceChargeUnit}
                            name="unit"
                            onChange={(e) =>
                              setMaintenanceChargeUnit(e.target.value)
                            }
                          >
                            <option value="K">Thousands (k)</option>
                            <option value="L">Lakhs (l)</option>
                            <option value="Cr">Crores (cr)</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="input-item input-item-textarea ">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder="Width"
                            value={width}
                            // onChange={(e) => setWidth(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-digit character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setWidth(sanitizedInput);
                            }}
                          />
                          <select
                            name="unit"
                            onChange={(e) => setWidthUnit(e.target.value)}
                            value={widthUnit}
                          >
                            <option value="sqyd">Square Yads (sqyd)</option>
                            <option value="sqft">Square Feet (sqft)</option>
                            <option value="sqm">Square Meters (sqm)</option>
                            <option value="acres">Acres</option>
                            <option value="hectares">Hectares</option>
                            <option value="guntha">Guntha</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-textarea ">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder="Length "
                            value={length}
                            // onChange={(e) => setLength(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-digit character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setLength(sanitizedInput);
                            }}
                          />
                          <select
                            name="unit"
                            onChange={(e) => setLengthUnit(e.target.value)}
                            value={lengthUnit}
                          >
                            <option value="sqyd">Square Yads (sqyd)</option>
                            <option value="sqft">Square Feet (sqft)</option>
                            <option value="sqm">Square Meters (sqm)</option>
                            <option value="acres">Acres</option>
                            <option value="hectares">Hectares</option>
                            <option value="guntha">Guntha</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="input-item input-item-textarea ">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder="Built Up Area"
                            value={builtupArea}
                            // onChange={(e) => setBuiltupArea(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-digit character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setBuiltupArea(sanitizedInput);
                            }}
                          // className="NumberInput"
                          />
                          <select
                            value={builtupAreaUnit}
                            name="unit"
                            onChange={(e) =>
                              setBuiltupAreaUnit(e.target.value)
                            }
                          >
                            <option value="sqft">Square Feet (sqft)</option>
                            <option value="sqm">Square Meters (sqm)</option>
                            <option value="sqyd">
                              Square Yards (sqyd)
                            </option>
                            <option value="sqin">
                              Square Inches (sqin)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-textarea ">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder="Carpet Area"
                            value={carpetArea}
                            // onChange={(e) => setCarpetArea(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-digit character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setCarpetArea(sanitizedInput);
                            }}
                          // className="NumberInput"
                          />
                          <select
                            value={carpetAreaUnit}
                            name="unit"
                            onChange={(e) =>
                              setCarpetAreaUnit(e.target.value)
                            }
                          >
                            <option value="sqft">Square Feet (sqft)</option>
                            <option value="sqm">Square Meters (sqm)</option>
                            <option value="sqyd">
                              Square Yards (sqyd)
                            </option>
                            <option value="sqin">
                              Square Inches (sqin)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-textarea ">
                          <input
                            type="text"
                            name="ltn__name"
                            placeholder="Price per units (Ex :- 2000/Sqft)"
                            value={sqftPrice}
                            // onChange={(e) => setCarpetArea(e.target.value)}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Replace any non-digit character with an empty string
                              const sanitizedInput = input.replace(/[^\d.]/g, '');
                              // Update state with sanitized input
                              setSqftPrice(sanitizedInput);
                            }}
                          // className="NumberInput"
                          />
                          <select
                            value={perSqfthUnit}
                            name="unit"
                            onChange={(e) =>
                              setperSqfthUnit(e.target.value)
                            }
                          >
                            <option value="sqft">Square Feet (sqft)</option>
                            <option value="sqm">Square Meters (sqm)</option>
                            <option value="sqyd">
                              Square Yards (sqyd)
                            </option>
                            <option value="sqin">
                              Square Inches (sqin)
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <h2>2. Media</h2>
                <h6>Listing Media</h6>
                {/* <input
                  type="file"
                  id="myFile"
                  name="filename"
                  className="btn theme-btn-3 mb-10 file_inputFile"
                  multiple
                  onChange={handleFileChange} // Bind the onChange event to handleFileChange
                /> */}
                <input id="file" type="file" multiple onChange={handleFileChange} />
                <br />
                <div className="image-container d-flex">
                  {images.map((image, index) => (
                    <div key={index} id="image-preview">
                      <img src={image.url} alt={`Preview ${index}`} />
                      <button className="rajesh" onClick={() => handleImageDelete(index)}>
                        <FaTrashAlt />
                      </button>
                    </div>
                  ))}
                </div>
                {/* <div id="image-preview">{renderImagePreviews()}</div> */}
                <p>
                  <small>
                    * At least 1 image is required for a valid submission.
                  </small>
                  <br />
                  <small>* If Multiple Images select all Images at Once.</small>
                  <br />
                  <small>* Images might take longer to be processed.</small>
                </p>
                <h2>3. Location</h2>
                <h6>Listing Location</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {errors.address && (
                        <div className="error-message">{errors.address}</div>
                      )}
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="*Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {/* <input
                        type="text"
                        name="ltn__name"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      /> */}
                      {errors.city && (
                        <div className="error-message">{errors.city}</div>
                      )}
                      <select
                        className="nice-select"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      >
                        <option value="">Select City</option>
                        {Data.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {errors.location && (
                        <div className="error-message">{errors.location}</div>
                      )}
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="State"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {/* <input
                        type="text"
                        name="ltn__name"
                        placeholder="Sub Location"
                        value={subLocation}
                        onChange={(e) => setSubLocation(e.target.value)}
                      /> */}
                      {errors.subLocation && (
                        <div className="error-message">
                          {errors.subLocation}
                        </div>
                      )}
                      <select
                        className="nice-select"
                        value={subLocation}
                        onChange={(e) => setSubLocation(e.target.value)}
                      >
                        <option value="">Sub Location</option>
                        {filteredAreas.map((area, index) => {
                          console.log(area);
                          return (
                            <option key={index} value={area}>
                              {area}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Landmark"
                        value={landmark}
                        onChange={(e) => setLandmark(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {errors.pincode && (
                        <div className="error-message">{errors.pincode}</div>
                      )}
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Pincode"
                        value={pincode}
                        // onChange={(e) => setPincode(e.target.value)}
                        onChange={(e) => {
                          const input = e.target.value;
                          // Replace any non-digit character with an empty string
                          const sanitizedInput = input.replace(/[^\d.]/g, '');
                          // Update state with sanitized input
                          setPincode(sanitizedInput);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="property-details-google-map mb-60">
                      {GpsLocationLink ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: GpsLocationLink }}
                        />
                      ) : (
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3425303.089462905!2d76.8146699311646!3d20.527718750567314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1646634634544!5m2!1sen!2sin"
                          width="100%"
                          height="100%"
                          frameBorder="0"
                          style={{ border: "0" }}
                          allowFullScreen
                          loading="lazy"
                        ></iframe>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Latitude (for Google Maps)"
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Longitude (for Google Maps)"
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="input-item input-item-name ltn__custom-icon">
                      {errors.GpsLocationLink && (
                        <div className="error-message">
                          {errors.GpsLocationLink}
                        </div>
                      )}
                      <input
                        type="text"
                        name="ltn__name"
                        placeholder="Gps Location Link (for Google Maps)"
                        value={GpsLocationLink}
                        onChange={(e) => setGpsLocationLink(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="alert alert-warning d-none" role="alert">
                  Please note that the date and time you requested may not be
                  available. We will contact you to confirm your actual
                  appointment details.
                </div>
                <div className="btn-wrapper text-center mt-30">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    {loading ? <SpinnerComponent /> : "Submit Property"}
                  </button>
                </div>
                {Object.keys(errors).length > 0 && (
                  <div className="error-section">
                    <ul>
                      {Object.values(errors).map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddListing;





// {
//   propertyType !== "Flat" ? (
//     <>
//       {/* Existing code for other fields */}
//       <div className="col-md-6">
//         <div className="input-item">
//           <select
//             value={bhk}
//             onChange={(e) => setBhk(e.target.value)}
//             className="nice-select"
//           >
//             <option value=""> Select BHK</option>
//             <option value="1Rk">1RK</option>
//             <option value="1BHK">1BHK</option>
//             <option value="2BHK">2BHK</option>
//             <option value="3BHK">3BHK</option>
//             <option value="3+BHK">3+BHK</option>
//           </select>
//         </div>
//       </div>
//       <div className="col-md-6">
//         <div className="input-item">
//           <select
//             value={bathrooms}
//             onChange={(e) =>
//               setBathrooms(e.target.value)
//             }
//             className="nice-select"
//           >
//             <option value=""> Select Bathrooms</option>
//             <option value="1">1</option>
//             <option value="2">2</option>
//             <option value="3">3</option>
//             <option value="3+">3+</option>
//           </select>
//         </div>
//       </div>
//     </>
//   ) : null
// }
