import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Instence from '../../utils/Instence';
import { loginSchema } from '../../utils/YupSchemas';
import { UserContext } from '../../utils/Auth';
import Swal from 'sweetalert2';
import { SpinnerComponent } from './add-listing';

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [forgotmail, setForgotmail] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [forgotpassword, setForgotpassword] = useState('');
	const [forgotconfirmpassword, setForgotconfirmpassword] = useState('');

	const { loginUser } = useContext(UserContext);
	const history = useHistory();

	const handleforgotSubmit = async (e) => {
		e.preventDefault();
		if (forgotpassword !== forgotconfirmpassword) {
			alert("Passwords do not match");
			return;
		}

		setLoading(true);

		try {
			const response = await Instence.post('/User/resetpassword', {
				email: forgotmail,
				password: forgotpassword
			});

			console.log(response.data);
			Swal.fire({
				icon: "success",
				title: "Reset Password Successful",
				text: "Reset Password Successfully!",
				timerProgressBar: true,
				showConfirmButton: false,
				timer: 3000,
			});
			setShowModal(false);

		} catch (error) {
			console.error(error.message);
			Swal.fire({
				icon: "error",
				title: "ERROR",
				text: " user not found " + error.message,
				timerProgressBar: true,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			await loginSchema.validate({ email, password }, { abortEarly: false });

			const formData = {
				email,
				password
			};

			console.log(formData);
			const response = await Instence.get(`/User/login?email=${email}&pass=${password}`);
			// ! Handle response
			console.log(response.data);
			Swal.fire({
				icon: "success",
				title: "Login Successful",
				text: "User logged in successfully!",
				timerProgressBar: true,
				showConfirmButton: false,
				timer: 3000,
			});
			sessionStorage.setItem('userData', JSON.stringify(response.data));
			if (response.data) {
				loginUser();
				history.push("/");
				window.scrollTo(0, 0);
			}
			setLoading(false);

		} catch (error) {
			if (error.inner) {
				const validationErrors = {};
				error.inner.forEach((e) => {
					validationErrors[e.path] = e.message;
				});
				setError(validationErrors);

				setTimeout(() => {
					setLoading(false);
				}, 3000);
			} else {
				console.log(error);
				// Handle other types of errors (if needed)
				Swal.fire({
					icon: "error",
					title: "ERROR",
					text: "An error occurred." + " user " + error.response.data,
					timerProgressBar: true,
					showConfirmButton: false,
					timer: 3000,
				});
			}
		}
	};

	let publicUrl = process.env.PUBLIC_URL + '/';

	return (
		<div>
			<div className="ltn__login-area pb-65">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title-area text-center">
								<h1 className="section-title">Sign In <br />To  Your Account</h1>
								{/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. <br />
									Sit aliquid,  Non distinctio vel iste.</p> */}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="account-login-inner">
								<form className="ltn__form-box contact-form-box">
									{error.email && <div className="error-message">{error.email}</div>}
									<input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="email" placeholder="Phone Number*" />
									{error.password && <div className="error-message">{error.password}</div>}
									<input value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" placeholder="Password*" />
									<div className="btn-wrapper mt-0">
										<button className="theme-btn-1 btn btn-block" onClick={handleSubmit} >SIGN IN</button>
									</div>
									<div className="go-to-btn mt-20">
										<p style={{ cursor: "pointer", color: "green" }} title="Forgot Password?" onClick={() => setShowModal(true)}><small>FORGOTTEN YOUR PASSWORD?</small></p>
									</div>
								</form>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="account-create text-center pt-50">
								<h4>DON'T HAVE AN ACCOUNT?</h4>
								<p>Add items to your wishlist get personalised recommendations <br />
									check out more quickly track your orders register</p>
								<div className="btn-wrapper go-top">
									<Link to="/register" className="theme-btn-1 btn black-btn">CREATE ACCOUNT</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showModal && (
				<div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="ltn__quick-view-modal-inner">
									<div className="modal-product-item">
										<div className="row">
											<div className="col-12">
												<div className="modal-product-info text-center">
													<h4>FORGET PASSWORD?</h4>
													<p className="added-cart"> Enter you register email.</p>
													<form onSubmit={handleforgotSubmit} className="ltn__form-box">
														<input value={forgotmail} onChange={(e) => setForgotmail(e.target.value)} type="text" name="email" placeholder="Type your register Email/Number*" />
														<input value={forgotpassword} onChange={(e) => setForgotpassword(e.target.value)} type="text" name="password" placeholder="Type your new password*" />
														<input value={forgotconfirmpassword} onChange={(e) => setForgotconfirmpassword(e.target.value)} type="text" name="confirm Password" placeholder="Type confirm password*" />
														<div className="btn-wrapper mt-0">
															<button className="theme-btn-1 btn btn-full-width-2" type="submit">{loading ? <SpinnerComponent /> : "Reset"}</button>
														</div>
													</form>
												</div>
												{/* additional-info */}
												<div className="additional-info d-none">
													<p>We want to give you <b>10% discount</b> for your first order, <br />  Use discount code at checkout</p>
													<div className="payment-method">
														<img src={publicUrl + "assets/img/icons/payment.png"} alt="#" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Login;









// 	const handleforgotSubmit = async (e) => {
// 		e.preventDefault();

// 		try {
// 			const response = await Instence.get(`/User/ForgotPassword?email=${forgotmail}`);
// 			console.log(response.data);


// 			if (response.data) {

// 				const bodyHtml = `
//       <p style="margin-bottom: 10px;"><strong>Your Password is :</strong> ${response.data.password}</p>
//       <p style="margin-bottom: 10px;">Thank you. We will get back to you as soon as possible.</p>
// `;


// 				const formData = new FormData();
// 				formData.append("To", response.data.email); // Update with your agent's email
// 				formData.append("Subject", "Forgot Password");
// 				formData.append("Body", bodyHtml);


// 				const res = await Instence.post("/Email/SendMail", formData);


// 				setShowModal(false);
// 				console.log(res.data)
// 			}


// 			Swal.fire({
// 				icon: "success",
// 				title: "Password Reset Email Sent",
// 				text: "An email to reset your password has been sent to your email address.",
// 				timerProgressBar: true,
// 				showConfirmButton: false,
// 				timer: 3000,
// 			});
// 		} catch (error) {
// 			Swal.fire({
// 				icon: "error",
// 				title: "Error",
// 				text: "Failed to send password reset email. Please try again.",
// 				timerProgressBar: true,
// 				showConfirmButton: false,
// 				timer: 3000,
// 			});
// 		}
// 	};

