import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Social from "../section-components/social";
import { UserContext } from "../../utils/Auth";
// import ButtonGroup from '@mui/material/ButtonGroup'
// import Button from '@mui/material/Button'

const NavbarV3 = () => {
  const [publicUrl, setPublicUrl] = useState(process.env.PUBLIC_URL + "/");
  const [imgattr, setImgAttr] = useState("logo");
  const [anchor, setAnchor] = useState("#");

  useEffect(() => {
    // Any side effects can go here
    // For example, fetching data, event listeners, etc.
  }, []); // empty dependency array means it will run only once after initial render

  const { userLogin } = useContext(UserContext);

  const history = useHistory();
  const { logoutUser } = useContext(UserContext);

  const handlelogout = () => {
    logoutUser();
    history.push("/");
    window.location.reload();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2">
        {/* ltn__header-top-area start */}
        <div className="ltn__header-top-area top-area-color-white d-none">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you">
                        <i className="icon-mail" /> WaytoLandmark@webmail.com
                      </a>
                    </li>
                    <li>
                      <a href="locations.html">
                        <i className="icon-placeholder" />
                        way2landmark realtors Pvt Ltd,opposite to hosanna
                        mandir, Rayapudi,522237
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        {/* ltn__language-menu */}
                        <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                          <ul>
                            <li>
                              <a href="#" className="dropdown-toggle">
                                <span className="active-currency">English</span>
                              </a>
                              <ul>
                                <li>
                                  <Link to="#">Arabic</Link>
                                </li>
                                <li>
                                  <Link to="#">Bengali</Link>
                                </li>
                                <li>
                                  <Link to="#">Chinese</Link>
                                </li>
                                <li>
                                  <Link to="#">English</Link>
                                </li>
                                <li>
                                  <Link to="#">French</Link>
                                </li>
                                <li>
                                  <Link to="#">Hindi</Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        {/* ltn__social-media */}
                        <div className="ltn__social-media">
                          <ul>
                            <li>
                              <a href="#" title="Facebook">
                                <i className="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li>
                              <a href="#" title="Twitter">
                                <i className="fab fa-twitter" />
                              </a>
                            </li>
                            <li>
                              <a href="#" title="Instagram">
                                <i className="fab fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a href="#" title="Dribbble">
                                <i className="fab fa-dribbble" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-top-area end */}
        {/* ltn__header-middle-area start */}
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo go-top">
                    <Link to="/">
                      <img
                        src={publicUrl + "assets/img/wayLogo2.png"}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="get-support clearfix d-none">
                    <div className="get-support-icon">
                      <i className="icon-call" />
                    </div>
                    <div className="get-support-info">
                      <h6>Get Support</h6>
                      <h4>
                        <a href="tel:+123456789">123-456-789-10</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column menu-color-white">
                <div className="header-menu d-none d-xl-block go-top">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>
                        <li className="menu-icon">
                          <Link to="/">Home</Link>
                        </li>
                        <li className="menu-icon">
                          <Link to="/about">About</Link>
                        </li>
                        {/* <li className="menu-icon"><Link to="/shop">Properties</Link>
												</li> */}
                     
                        <li className="menu-icon">
                          <Link to="/service">Services</Link>
                        </li>
                        <li className="menu-icon"><Link to="/blog">Blogs</Link>
												</li>
                        <li className="menu-icon">
                          <Link to="/contact">Contact</Link>
                        </li>
                        {!userLogin ? (
                          <>
                            <li className="special-link">
                              <Link to="/login">Login</Link>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link to="/my-account">My Account</Link>
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/add-listing">Add Listing</Link>
                                </li>
                                <li onClick={handlelogout}>
                                  <Link>Logout</Link>
                                </li>
                              </ul>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col--- ltn__header-options ltn__header-options-2 ">
                {/* Mobile Menu Button */}
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#"
                    className="ltn__utilize-toggle"
                    onClick={toggleMenu}
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-middle-area end */}
      </header>

      <div
        id="ltn__utilize-mobile-menu"
        className={`ltn__utilize ${isOpen ? "ltn__utilize-open" : ""}`}
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <Link to="/">
                <img src={publicUrl + "assets/img/wayLogo2.png"} alt="Logo" />
              </Link>
            </div>
            <button onClick={closeMenu} className="ltn__utilize-close">
              ×
            </button>
          </div>
          <div className="ltn__utilize-menu-search-form">
            <form action={"#"}>
              <input type="text" placeholder="Search..." />
              <button>
                <i className="fas fa-search" />
              </button>
            </form>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              {/* <li ><Link to="/shop">Properties</Link>
							</li> */}
            
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li ><Link to="/blog">Blogs</Link>
							</li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              {userLogin ? (
                <>
                  <li>
                    <Link to="/my-account">My Account</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/add-listing">Add Listing</Link>
                      </li>
                      <li onClick={handlelogout}>
                        <Link>Logout</Link>
                      </li>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/register">Register</Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div className="ltn__utilize-overlay" onClick={toggleMenu}></div>
      )}
    </div>
  );
};

export default NavbarV3;
