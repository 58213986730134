import React, { useEffect, useState } from "react";
import SlickSlider from "react-slick";
import Instence, { imageURL } from "../../utils/Instence";

const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const publicUrl = process.env.PUBLIC_URL + "/";

  // Fetch Testimonials from the API
  const GetTestimonials = async () => {
    try {
      const response = await Instence.get("Testimonial");
      if (response.status === 200) {
        setTestimonials(response.data);
        console.log("Testimonial", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetTestimonials();
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
      data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Testimonial
              </h6>
              <h1 className="section-title">Clients Feedback</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
          <SlickSlider {...sliderSettings}>
            {testimonials.map((testimonial) => (
              <div className="col-lg-4" key={testimonial.id}>
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1" />
                      {testimonial.testimonialText}
                    </p>
                    <div className="ltn__testimoni-info-inner" style={{display:"flex", alignItems:"center"}}>
                      <div className="ltn__testimoni-img">
                        <img src={`${imageURL}/uploads/${testimonial.customerPhoto}`} alt="#" style={{height:"60px", width:"60px"}} />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>{testimonial.name}</h5>
                        <label>{testimonial.designation}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
