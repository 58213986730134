import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Instence, { imageURL } from '../../utils/Instence';

const BlogGrid = () => {
  const publicUrl = process.env.PUBLIC_URL + '/';

  const [blogData, setBlogData] = useState([]);

// ***************************** Get Blogs Api Here ******************************************

const GetBlogs = async() => {
  try {
    const response = await Instence.get("Blogs");
    if(response.status === 200){
      setBlogData(response.data);
      console.log("Blogs", response.data)
    }
  } catch (error) {
    console.log(error);
  }
}

useEffect(() => {
  GetBlogs();
}, []);




  // const blogData = [
  //   {
  //     id: 1,
  //     title: 'Common Engine Oil Problems and Solutions',
  //     image: '1.jpg',
  //     author: 'Admin',
  //     tags: 'Services',
  //     date: 'June 24, 2020',
  //   },
  //   {
  //     id: 2,
  //     title: 'How and when to replace brake rotors',
  //     image: '2.jpg',
  //     author: 'Admin',
  //     tags: 'Services',
  //     date: 'July 23, 2020',
  //   },
  //   // Add more blog items here...
  // ];


  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Ensure month is two digits
    const day = String(d.getDate()).padStart(2, "0"); // Ensure day is two digits 
    return `${day}-${month}-${year}`;
  };
  

  return (
    <div className="ltn__blog-area ltn__blog-item-3-normal mb-100 go-top">
      <div className="container">
        <div className="row">
          {blogData.map((blog) => (
            <div className="col-lg-4 col-sm-6 col-12" key={blog.id}>
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img" style={{height:"250px"}}>
                  <Link to={`/blog-details/${blog.id}`}>
                    <img src={`${imageURL}/uploads/${blog.featuredImage}`} alt={blog.title} style={{height:"100%", width:"100%", objectFit:"cover"}}/>
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author go-top">
                        
                          <i className="far fa-user" /> by: {blog.author}
                       
                      </li>
                      <li className="ltn__blog-tags go-top">
                       
                          <i className="fas fa-tags" /> {blog.category}
                        
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to={`/blog-details/${blog.id}`} >{blog.title}</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" /> {formatDate(blog.publicationDate)}
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to={`/blog-details/${blog.id}`}>Read more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogGrid;
