import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV5 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img
                  src={publicUrl + "assets/img/service/02.jpg"}
                  alt="Image"
                />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    The Leading Real Estate Rental Marketplace<span>.</span>
                  </h1>
                  <p>
                    We have a dedicated team of professionals based in Guntur,
                    offering specialized services to our valued clients. Our
                    team is committed to delivering exceptional results and
                    providing unparalleled support locally and beyond.
                  </p>
                </div>
                <div className="about-us-info-wrap-inner about-us-info-devide---">
                  <p>
                    Our mission is to provide exceptional rental solutions
                    tailored to meet your needs.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link
                    to="/about"
                    className="theme-btn-1 btn btn-effect-1 text-uppercase"
                  >
                    About Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV5;
