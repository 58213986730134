import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Instence from "../../utils/Instence";
import profileImg from "../../Img/team/blank-profile.png";
import dummyProperty from "../../Img/team/properties.jpg";
const ShopDetails = ({ userId }) => {
  console.log(userId);
  const [storedUserId, setStoredUserId] = useState(null);
  const [userdata, setUserData] = useState([]);
  const [agentdata, setAgentData] = useState([]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [userId]);

  const fetchData = async () => {
    try {
      const response = await Instence.get(`/Property/GetByPropIdOnlyTrue?Id=${userId}`);
      console.log("Single Property user Id", response.data.userId);
      setStoredUserId(response.data.userId);
      setUserData(response.data);
      // Handle response data as needed
    } catch (error) {
      console.error("Error fetching Single Property data:", error);
    }
  };
  console.log(storedUserId);
  const ID = storedUserId;
  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        const response = await Instence.get(`User/${ID}`);
        console.log(ID);
        if (response.status === 200) {
          setAgentData(response.data);
          console.log(response.data);
          console.log("Single Agent data fetched successfully !");
        }
        // Handle response data as needed
      } catch (error) {
        console.error("Error fetching Single Agent Data:", error);
      }
    };

    fetchAgentData();
  }, [ID]);
  console.log("SingleAgentData", agentdata);
  const [allProperties, setAllProperties] = useState([]);
  //   const [randomProperties, setRandomProperties] = useState([]);

  const fetchAllProperties = async () => {
    try {
      const response = await Instence.get("/Property/GetAllOnlyTrue");
      if (response.status === 200) {
        console.log("Successfully fetched all properties");
        console.log(response.data);
        setAllProperties(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();

    fetchAllProperties();
  }, [userId]);

  let publicUrl = process.env.PUBLIC_URL + "/";

// ********************** Email API Here *****************************************************

  const [error, setError] = useState(null);

  const [yourname, setYourName] = useState("");
  const [youremail, setYourEmail] = useState("");
  const [yourmessage, setYourMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const bodyHtml = `
        <p><strong>Name:</strong> ${yourname}</p>
        <p><strong>Email:</strong> ${youremail}</p>
        <p><strong>Message:</strong></p>
        <p>${yourmessage}</p>
      `;

      const formData = new FormData();
      formData.append("To", "way2landmarkk@gmail.com");
      formData.append("Subject", "Message from user");
      formData.append("Body", bodyHtml);

      const response = await Instence.post("/Email/SendMail", formData);

      if (response.status === 200) {
        console.log("Message sent successfully!");

        setYourName("");
        setYourEmail("");
        setYourMessage("");
      } else {
        setError("Failed to send message");
      }
    } catch (error) {
      setError("An error occurred while sending message");
    }
  };

// ***************************************************************************

  // Function to get random properties
  const getRandomProperties = (array, count) => {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  // Get two random properties from allProperties array
  const randomProperties = getRandomProperties(allProperties, 2);

  const parseDimension = (dimension) => {
    const match = dimension.match(/^(\d+(\.\d+)?)(\s*(sqft|sqyd|sqm|sqin))$/);
    if (match) {
      return { value: parseFloat(match[1]), unit: match[4] };
    }
    return null;
  };

  const calculateArea = (width, length) => {
    const parsedWidth = parseDimension(width);
    const parsedLength = parseDimension(length);

    if (parsedWidth && parsedLength && parsedWidth.unit === parsedLength.unit) {
      const area = parsedWidth.value * parsedLength.value;
      return `${area.toFixed(2)} ${parsedWidth.unit}`;
    }
    return "N/A"; // Or handle unit conversion if needed
  };


  const area = userdata.propertyType === "Plot" || userdata.propertyType === "Agricultural Land"
  ? calculateArea(userdata.width, userdata.length)
  : null;


  return (
    <div className="ltn__shop-details-area pb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  {userdata.propertyType && (
                    <li className="ltn__blog-category">
                      <Link className="bg-orange" to="#">
                        {userdata.propertyType}
                      </Link>
                    </li>
                  )}

                  <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt" />

                    {new Date(userdata.createdAt).toLocaleDateString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </li>
                </ul>
              </div>
              <h1>{userdata && userdata.title}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {userdata && userdata.cityName}, {userdata && userdata.subLocation},{" "}
                {userdata && userdata.pincode}
              </label>
              <h4 className="title-2">Description</h4>
              <p>{userdata && userdata.description}</p>
              <h4 className="title-2">Property Detail</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                <ul>
                  <li>
                    <label>Property ID:</label> <span>{userdata.id}</span>
                  </li>

                  {userdata.proprtyState && (
                    <li>
                      <label>Property State:</label>{" "}
                      <span>{userdata.proprtyState}</span>
                    </li>
                  )}

                  {userdata.propertyPrice && (
                    <li>
                      <label>Property Price:</label>{" "}
                      <span>{userdata.propertyPrice}</span>
                    </li>
                  )}
                  {userdata.sqftPrice && (
                    <li>
                      <label>Per Sqft Price:</label>{" "}
                      <span>{userdata.sqftPrice}</span>
                    </li>
                  )}

                  {userdata.maintanenceCharge && (
                    <li>
                      <label>Maintanance Charge:</label>{" "}
                      <span>{userdata.maintanenceCharge}</span>
                    </li>
                  )}
                  {userdata.propertyType && (
                    <li>
                      <label>Lot PropertyType:</label>{" "}
                      <span>{userdata.propertyType}</span>
                    </li>
                  )}

                  {userdata.propertyType === "Plot" || userdata.propertyType === "Flat" ||
                    userdata.propertyType === "Agricultural Land" ? undefined : (
                    <>
                      {userdata.bhk && (
                        <li>
                          <label>BHK:</label> <span>{userdata.bhk}</span>
                        </li>
                      )}
                      {userdata.bathrooms && (
                        <li>
                          <label>Bathrooms:</label>{" "}
                          <span>{userdata.bathrooms}</span>
                        </li>
                      )}
                    </>
                  )}
                  {userdata.category && (
                    <li>
                      <label>Category:</label> <span>{userdata.category}</span>
                    </li>
                  )}
                  {userdata.yearBuilt && (
                    <li>
                      <label>Year built:</label>{" "}
                      <span>{userdata.yearBuilt}</span>
                    </li>
                  )}
                  {userdata.landmark && (
                    <li>
                      <label>Landmark:</label> <span>{userdata.landmark}</span>
                    </li>
                  )}
                  {userdata.furnishType && (
                    <li>
                      <label>Furnish Type:</label>{" "}
                      <span>{userdata.furnishType}</span>
                    </li>
                  )}
                </ul>
                <ul>
                  {userdata.lotArea && (
                    <li>
                      <label>Lot Area:</label> <span>{userdata.lotArea}</span>
                    </li>
                  )}

                  {userdata.price && (
                    <li>
                      <label>Price:</label> <span>{userdata.price}</span>
                    </li>
                  )}
                  {/* {userdata.constructionStatus && (
                    <li>
                      <label>Construction Status:</label>{" "}
                      <span>{userdata.constructionStatus}</span>
                    </li>
                  )} */}
                  {userdata.processionStatus && (
                    <li>
                      <label>Property Status:</label>{" "}
                      <span>{userdata.processionStatus}</span>
                    </li>
                  )}

                  {userdata.propertyType === "Plot" ||
                    userdata.propertyType === "Agricultural Land" ? (
                    <>
                      {userdata.length && (
                        <li>
                          <label>Property Length:</label>{" "}
                          <span>{userdata.length}</span>
                        </li>
                      )}
                      {userdata.width && (
                        <li>
                          <label>Property Width:</label>{" "}
                          <span>{userdata.width}</span>
                        </li>
                      )}
                             {userdata.totalArea ? (
                        <li>
                          <label>Property Total Area:</label>{" "}
                          <span>{userdata.totalArea}</span>
                        </li>
                      ) : (
                        <li>
                        <label>Property Total Area:</label>{" "}
                        <span>{area}</span>
                      </li>
                      )
                    }
                      {userdata.widthOfFacingRoad && (
                        <li>
                          <label>Width of facing Road:</label>{" "}
                          <span>{userdata.widthOfFacingRoad}</span>
                        </li>
                      )}
                    </>
                  ) : undefined}
                  {userdata.facing && (
                    <li>
                      <label>facing:</label>{" "}
                      <span>{userdata.facing}</span>
                    </li>
                  )}
                  {
                    userdata.propertyType === "Plot" ||
                      userdata.propertyType === "Agricultural Land" ? undefined : (
                      <>
                        {userdata.builtupArea && userdata.builtupArea == ' sqft' ? undefined : (
                          <li>
                            <label>Builtup Area:</label>{" "}
                            <span>{userdata.builtupArea}</span>
                          </li>
                        )}
                        {userdata.carpetArea && userdata.carpetArea == ' sqft' ? undefined : (
                          <li>
                            <label>Carpet Area:</label>{" "}
                            <span>{userdata.carpetArea}</span>
                          </li>
                        )}
                      </>
                    )
                  }
                  {userdata.covered2WheelParking && (
                    <li>
                      <label>Covered 2 wheel Parking:</label>{" "}
                      <span>{userdata.covered2WheelParking}</span>
                    </li>
                  )}
                  {userdata.covered4WheelParking && (
                    <li>
                      <label>Covered 4 wheel Parking:</label>{" "}
                      <span>{userdata.covered4WheelParking}</span>
                    </li>
                  )}
                  {userdata.open2WheelParking && (
                    <li>
                      <label>Open 2 wheel Parking:</label>{" "}
                      <span>{userdata.open2WheelParking}</span>
                    </li>
                  )}
                  {userdata.open2WheelParking && (
                    <li>
                      <label>Open 4 wheel Parking:</label>{" "}
                      <span>{userdata.open2WheelParking}</span>
                    </li>
                  )}
                </ul>
              </div>
              <h4 className="title-2">Location</h4>
              <div className="property-details-google-map mb-150">
                {/* Rendering iframe dynamically based on userData.mapUrl */}
                {userdata.gpslocation && (
                  <div dangerouslySetInnerHTML={{ __html: userdata.gpslocation }} />
                )}
              </div>

              <div className="row">
                <h4 className="title-2">Related Properties</h4>
                {/* ltn__product-item */}
                {randomProperties.map((property) => (
                  <div
                    className="col-xl-6 col-sm-6 col-12 go-top"
                    key={property.id}
                  >
                    <div className="ltn__product-item ltn__product-item-4 text-center---">
                      <div className="product-img go-top">
                        <Link to={`/product-details/${property.id}`}>
                          {property.propertyImages &&
                            property.propertyImages.length > 0 ? (
                            <img
                              src={`https://way2landmark20240705114540.azurewebsites.net/uploads/${property.propertyImages[0].imageName}`}
                              alt="#"
                            />
                          ) : (
                            <img src={dummyProperty} alt="Alternative Image" />
                          )}
                        </Link>
                        <div className="product-badge">
                          <ul>
                            <li className="sale-badge bg-green">
                              {property.proprtyState}
                            </li>
                          </ul>
                        </div>
                        <div className="product-img-location-gallery">
                          <div className="product-img-location">
                            <ul>
                              <li>
                                <Link to="/contact">
                                  <i className="flaticon-pin" /> {property.cityName}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="product-info">
                        <div className="product-price">
                          <span>
                            ₹{property.propertyPrice}
                          </span>
                        </div>
                        <h2 className="product-title go-top">
                          <Link to={`/product-details/${property.id}`}>
                            {property.title}
                          </Link>

                        </h2>
                        <span>{property.facing}</span>
                        <div className="product-description">
                          <p>{property.description}</p>
                        </div>
                        {property.propertyType === "Apartment" ||
                          property.propertyType === "Flat" ||
                          property.propertyType === "Independent House" ||
                          property.propertyType === "Villa" && (
                            <ul className="ltn__list-item-2 ltn__list-item-2-before">
                              <li>
                                <span>
                                  {property.bhk} <i className="flaticon-bed" />
                                </span>
                                Bedrooms
                              </li>
                              <li>
                                <span>
                                  {property.bathrooms} <i className="flaticon-clean" />
                                </span>
                                Bathrooms
                              </li>
                              {property.length && property.width && (
                                <li>
                                  <span>
                                    {property.length * property.width}{" "}
                                    <i className="flaticon-square-shape-design-interface-tool-symbol" />
                                  </span>
                                  square Ft
                                </li>
                              )}
                            </ul>
                          )}
                        {
                          property.propertyType === "Plot" ||
                          property.propertyType === "Agricultural Land" && (
                            <ul className="ltn__list-item-2 ltn__list-item-2-before">
                              <li>
                                <span>
                                  {property.width}
                                </span>
                                Width
                              </li>
                              <li>
                                <span>
                                  {property.length}
                                </span>
                                Length
                              </li>
                              <li>
                                <span>
                                  {property.Facing}
                                </span>
                                Facing
                              </li>
                            </ul>
                          )
                        }
                      </div>
                      {/* <div className="product-info-bottom">
                        <div className="product-hover-action">
                          <ul>
                            <li>
                              <a title="Wishlist">
                                <i className="flaticon-heart-1" />
                              </a>
                            </li>
                            <li>
                              <span className="go-top">
                                <Link
                                  to={`/product-details/${property.id}`}
                                  title="Product Details"
                                >
                                  <i className="flaticon-add" />
                                </Link>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              {/* Author Widget */}
              <div className="widget ltn__author-widget">
                <div className="ltn__author-widget-inner text-center">
                  {/* {agentdata.imageName && agentdata.imageName > 0 ? <>
                    <img src={profileImg} alt="Image" />
                  </> : <>
                    <img src={`https://way2landmark20240705114540.azurewebsites.net/uploads/${agentdata.image}`} alt="Image" />
                  </>} */}
                  <h5>{agentdata.firstName + " " + agentdata.lastName}</h5>
                  {/* <p className="m-0">{agentdata.role}</p>
                  <small>{agentdata.email}</small> */}
                  {/* <div className="product-ratting">
									<ul>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star" /></a></li>
										<li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
										<li><a href="#"><i className="far fa-star" /></a></li>
										<li className="review-total"> <a href="#"> ( 1 Reviews )</a></li>
									</ul>
								</div> */}
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio, odio, eligendi suscipit reprehenderit atque.</p>
								<div className="ltn__social-media">
									<ul>
										<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
										<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
										<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
										<li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
									</ul>
								</div> */}
                </div>
              </div>
              {/* Search Widget */}
              {/* <div className="widget ltn__search-widget">
								<h4 className="ltn__widget-title ltn__widget-title-border-2">Search Objects</h4>
								<form action="#">
									<input type="text" name="search" placeholder="Search your keyword..." />
									<button type="submit"><i className="fas fa-search" /></button>
								</form>
							</div> */}
              {/* Form Widget */}
              <div className="widget ltn__form-widget">
                <h4 className="ltn__widget-title ltn__widget-title-border-2">
                  Drop Message For Book
                </h4>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="yourname"
                    value={yourname}
                    onChange={(e) => setYourName(e.target.value)}
                    placeholder="Your Name*"
                    required
                  />
                  <input
                    type="email"
                    name="youremail"
                    value={youremail}
                    onChange={(e) => setYourEmail(e.target.value)}
                    placeholder="Your e-Mail*"
                    required
                  />
                  <textarea
                    name="yourmessage"
                    value={yourmessage}
                    onChange={(e) => setYourMessage(e.target.value)}
                    placeholder="Write Message..."
                    required
                  />
                  <button type="submit" className="btn theme-btn-1">
                    Send Message
                  </button>
                </form>
                {error && <p>{error}</p>}
              </div>
              {/* Banner Widget */}
              <div className="widget ltn__banner-widget d-none go-top">
                <Link to="/shop">
                  <img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
