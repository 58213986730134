import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Sidebar from './sidebar';
import Instence, { imageURL } from '../../utils/Instence';


const BlogDetails = () => {
  const publicUrl = process.env.PUBLIC_URL + '/';
  const imagealt = 'image';

  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState({});
  const GetBlogDetailsById = async () => {
    try {
      const response = await Instence.get(`Blogs/${id}`);
      if (response.status === 200) {
        console.log("BlogDetails", response.data);
        setBlogDetails(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetBlogDetailsById();
  }, []);



  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Ensure month is two digits
    const day = String(d.getDate()).padStart(2, "0"); // Ensure day is two digits 
    return `${day}-${month}-${year}`;
  };
  


  return (
    <div className="ltn__page-details-area ltn__blog-details-area mb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="ltn__blog-details-wrap">
              <div className="ltn__page-details-inner ltn__blog-details-inner">
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-category">
                      <Link >{blogDetails.category}</Link>
                    </li>
                  </ul>
                </div>
                <h2 className="ltn__blog-title">
                 {blogDetails.title}
                </h2>
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-author go-top">
                      <Link to="/team-details">
                        <img src={publicUrl + 'assets/img/blog/author.jpg'} alt={imagealt} />
                        By: {blogDetails.author}
                      </Link>
                    </li>
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />{formatDate(blogDetails.publicationDate)}
                    </li>
                    {/* <li>
                      <Link to="#">
                        <i className="far fa-comments" />
                        35 Comments
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <p>
                {blogDetails.shortDescription}
                </p>
                <div style={{width:"100%", height:"400px"}}>

                <img src={`${imageURL}/uploads/${blogDetails.featuredImage}`} alt={imagealt} style={{height:"100%", width:"100%", objectFit:"cover"}}/>
				</div>
               
				{blogDetails.description}
              </div>
              {/* <Comments /> */}
            </div>
          </div>

          <Sidebar blogDetails={blogDetails}/>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
