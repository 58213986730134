import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ShogGrid from './shop-components/shop-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Instence from '../utils/Instence';

const Shop_V1 = () => {


    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);

    // const propType = searchParams.get('propType');
    // const selectedCity = searchParams.get('selectedCity');
    // const selectedSubLocation = searchParams.get('selectedSubLocation');
    // const selectedListing = searchParams.get('selectedListing');


    // console.log(propType);
    // console.log(selectedCity);
    // console.log(selectedSubLocation);
    // console.log(selectedListing);


    // useEffect(() => {
    //     const handleSearch = async () => {
    //         try {
    //             const response = await Instence.post('/Property/Search', {
    //                 // propertyType: propType,
    //                 // city: selectedCity,
    //                 // subLocation: selectedSubLocation,
    //                 listingType: selectedListing
    //             });
    //             // Assuming response.data contains the fetched properties
    //             const properties = response.data;
    //             console.log(properties)
    //             // Navigate to the shop page and pass the fetched properties as state
    //         } catch (error) {
    //             console.log(error)
    //             // Handle any errors here
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: "Match not found",
    //                 text: error,
    //                 timer: 3000,
    //                 timerProgressBar: true
    //             });
    //         }
    //     };
    //     handleSearch()

    // }, [])

    const [searchData, setSearchData] = useState([])



    const [searchParams, setSearchParams] = useState("");

    console.log(searchParams)

    useEffect(() => {
        // Retrieve the search parameters from localStorage
        const paramsString = localStorage.getItem('searchParams');
        if (paramsString) {
            const params = JSON.parse(paramsString);
            setSearchParams(params);
        }
    }, []);

    useEffect(() => {
        const postDataToApi = async () => {
            if (searchParams) {
                try {
                    const postData = {
                        propertyType: searchParams.propType,
                        city: searchParams.selectedCity,
                        subLocation: searchParams.selectedSubLocation,
                        listingType: searchParams.listingType
                    };
                    const response = await Instence.post("/Property/SearchPropByStatusTrue", postData);
                    // Handle successful response
                    console.log('API response:', response.data)
                    setSearchData(response.data)
                } catch (error) {
                    // Handle error
                    console.error('Error posting data to API:', error);
                }
            }
        };

        postDataToApi();
    }, [searchParams]);





    return <div>
        <Navbar />
        <PageHeader location={searchParams.selectedCity} sublocation={searchParams.selectedSubLocation} search={searchParams.propType} subheader={searchParams.listingType} headertitle="Properties List" />
        <ShogGrid searchParams={searchParams} searchptype={searchParams.propType} searchData={searchData} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Shop_V1

