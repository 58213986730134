import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Instence from '../../utils/Instence';

const Sidebar = ({ blogDetails }) => {
  let anchor = '#';
  let imagealt = 'image';
  let publicUrl = process.env.PUBLIC_URL + '/';


// ************************* EMAIL Api Here ************************************

const [error, setError] = useState("");
const [yourname, setYourName] = useState("");
const [youremail, setYourEmail] = useState("");
const [yourmessage, setYourMessage] = useState("");

const handleSubmit = async (e) => {
  e.preventDefault();
  setError(null);

  try {
    const bodyHtml = `
      <p><strong>Name:</strong> ${yourname}</p>
      <p><strong>Email:</strong> ${youremail}</p>
      <p><strong>Message:</strong></p>
      <p>${yourmessage}</p>
    `;

    const formData = new FormData();
    formData.append("To", "way2landmarkk@gmail.com");
    formData.append("Subject", "Message from user");
    formData.append("Body", bodyHtml);

    const response = await Instence.post("/Email/SendMail", formData);

    if (response.status === 200) {
      console.log("Message sent successfully!");
      setYourName("");
      setYourEmail("");
      setYourMessage("");
    } else {
      setError("Failed to send message");
    }
  } catch (error) {
    setError("An error occurred while sending message");
  }
};


// *****************************************************************************************




  return (
    <div className="col-lg-4 go-top">
      <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
        {/* Author Widget */}
        <div className="widget ltn__author-widget">
          <div className="ltn__author-widget-inner text-center">
            <img src={publicUrl + 'assets/img/team/4.jpg'} alt="Image" />

            {/* ******************* Need To edit This Page *********************************************** */}

            <h5>{blogDetails.author}</h5>
            {/* <small>Traveller/Photographer</small>
            <div className="product-ratting">
              <ul>
                <li><a href="#"><i className="fas fa-star" /></a></li>
                <li><a href="#"><i className="fas fa-star" /></a></li>
                <li><a href="#"><i className="fas fa-star" /></a></li>
                <li><a href="#"><i className="fas fa-star-half-alt" /></a></li>
                <li><a href="#"><i className="far fa-star" /></a></li>
                <li className="review-total"> <a href="#"> ( 1 Reviews )</a></li>
              </ul>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis distinctio, odio, eligendi suscipit reprehenderit atque.</p>
            <div className="ltn__social-media">
              <ul>
                <li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
                <li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
                <li><a href="#" title="Youtube"><i className="fab fa-youtube" /></a></li>
              </ul>
            </div> */}
          </div>
        </div>
        {/* Search Widget */}
        {/* <div className="widget ltn__search-widget">
          <h4 className="ltn__widget-title ltn__widget-title-border-2">Search Objects</h4>
          <form action="#">
            <input type="text" name="search" placeholder="Search your keyword..." />
            <button type="submit"><i className="fas fa-search" /></button>
          </form>
        </div> */}
        {/* Form Widget */}
        <div className="widget ltn__form-widget">
          <h4 className="ltn__widget-title ltn__widget-title-border-2">Drop Message For Book</h4>
          <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="yourname"
                    value={yourname}
                    onChange={(e) => setYourName(e.target.value)}
                    placeholder="Your Name*"
                    required
                  />
                  <input
                    type="email"
                    name="youremail"
                    value={youremail}
                    onChange={(e) => setYourEmail(e.target.value)}
                    placeholder="Your e-Mail*"
                    required
                  />
                  <textarea
                    name="yourmessage"
                    value={yourmessage}
                    onChange={(e) => setYourMessage(e.target.value)}
                    placeholder="Write Message..."
                    required
                  />
                  <button type="submit" className="btn theme-btn-1">
                    Send Message
                  </button>
                </form>
                {error && <p>{error}</p>}
        </div>
        {/* Popular Product Widget */}
        {/* <div className="widget ltn__popular-product-widget">
          <h4 className="ltn__widget-title ltn__widget-title-border-2">Popular Properties</h4>
          <div className="row ltn__popular-product-widget-active slick-arrow-1">
            <div className="col-12">
              <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                <div className="product-img">
                  <Link to="/product-details"><img src={publicUrl + "assets/img/product-3/6.jpg"} alt="#" /></Link>
                  <div className="real-estate-agent">
                    <div className="agent-img">
                      <Link to="/team-details"><img src={publicUrl + "assets/img/blog/author.jpg"} alt="#" /></Link>
                    </div>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-price">
                    <span>$349,00<label>/Month</label></span>
                  </div>
                  <h2 className="product-title"><Link to="/product-details">New Apartment Nice View</Link></h2>
                  <div className="product-img-location">
                    <ul>
                      <li>
                        <Link to="/product-details"><i className="flaticon-pin" /> Belmont Gardens, Chicago</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                    <li><span>3 </span>
                      Bedrooms
                    </li>
                    <li><span>2 </span>
                      Bathrooms
                    </li>
                    <li><span>3450 </span>
                      square Ft
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </aside>
    </div>
  );
};

export default Sidebar;
