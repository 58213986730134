import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useParams } from 'react-router';

const Product_Details = () => {

    const { id } = useParams();
    console.log(id)


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);


    return <div>
        <Navbar />
        <PageHeader headertitle="Product Details" customclass="mb-0" />
        <ProductSlider userId={id} />
        <ProductDetails userId={id} />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Product_Details

