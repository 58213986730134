import React, { useState } from "react";
import Instence from "../../utils/Instence";
import Swal from "sweetalert2";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    phone: "",
    message: "",
    agree: false,
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const bodyHtml = `
       <div style="font-family: Arial, sans-serif; font-size: 16px; color: #333;">
      <p style="margin-bottom: 10px;"><strong>Name:</strong> ${
        formData.name
      }</p>
      <p style="margin-bottom: 10px;"><strong>Email:</strong> ${
        formData.email
      }</p>
      <p style="margin-bottom: 10px;"><strong>Service:</strong> ${
        formData.service
      }</p>
      <p style="margin-bottom: 10px;"><strong>Phone:</strong> ${
        formData.phone
      }</p>
      <p style="margin-bottom: 10px;"><strong>Message:</strong></p>
      <p style="margin-bottom: 10px;">${formData.message}</p>
      <p style="margin-bottom: 10px;"><strong>Agreed:</strong> ${
        formData.agree ? "Yes" : "No"
      }</p>
      <p style="margin-bottom: 10px;">Thank you for your inquiry. We will get back to you as soon as possible.</p>
    </div>
      `;

      const emailFormData = new FormData();
      emailFormData.append("To", "way2landmarkk@gmail.com"); // Update with your agent's email
      emailFormData.append("Subject", "Service Inquiry");
      emailFormData.append("Body", bodyHtml);

      const response = await Instence.post("/Email/SendMail", emailFormData);

      if (response.data.statusCode === 200) {
        console.log("Inquiry sent successfully!");
        Swal.fire({
          icon: "success",
          title: "Inquiry sent successfully!",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setFormData({
          name: "",
          email: "",
          service: "",
          phone: "",
          message: "",
          agree: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to send inquiry",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      setError("An error occurred while sending inquiry");
    }
  };

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Get A Quote</h4>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item">
                      <select
                        className="nice-select"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        required
                      >
                        <option>Select Service Type</option>
                        <option>Property Management </option>
                        <option>Mortgage Service </option>
                        <option>Consulting Service</option>
                        <option>Home Buying</option>
                        <option>Home Selling</option>
                        <option>Escrow Services</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Enter phone number"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter message"
                    required
                  />
                </div>
                <p>
                  <label className="input-info-save mb-0">
                    <input
                      type="checkbox"
                      name="agree"
                      checked={formData.agree}
                      onChange={handleChange}
                    />{" "}
                    Save my name, email, and website in this browser for the
                    next time I comment.
                  </label>
                </p>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                  >
                    get a free service
                  </button>
                </div>
                <p className="form-messege mb-0 mt-20" />
              </form>

              {error && <p className="error_message mt-5">{error}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
