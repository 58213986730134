import React, { Component } from "react";
import { Link } from "react-router-dom";

class Sidebar extends Component {
  render() {
    // let anchor = '#'
    // let imagealt = 'image'
    // let publicUrl = process.env.PUBLIC_URL + '/'
    return (
      <div className="col-lg-4  mb-100">
        <aside className="sidebar ltn__shop-sidebar">
          <h3 className="mb-10">Advance Information</h3>
          <label className="mb-30">
            <small>About 9,620 results (0.62 seconds) </small>
          </label>
          <div className="widget ltn__menu-widget">
            <h4 className="ltn__widget-title">Property Type</h4>
            <ul>
              <li>
                <label className="checkbox-item">
                  Apartment
                  <input type="checkbox" defaultChecked="checked" />
                  <span className="checkmark" />
                </label>
                <span className="categorey-no">3,924</span>
              </li>
              <li>
                <label className="checkbox-item">
                  Flat
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <span className="categorey-no">3,610</span>
              </li>
              <li>
                <label className="checkbox-item">
                  House
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <span className="categorey-no">2,912</span>
              </li>
              <li>
                <label className="checkbox-item">
                  {" "}
                  Villa
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <span className="categorey-no">2,687</span>
              </li>
              <li>
                <label className="checkbox-item">
                  Plot
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <span className="categorey-no">1,853</span>
              </li>
              <li>
                <label className="checkbox-item">
                  Agricultural Land
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
                <span className="categorey-no">893</span>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    );
  }
}

export default Sidebar;
