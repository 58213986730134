import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();


const UserProvider = ({ children }) => {
    const [userLogin, setUserLogin] = useState(() => {
        const storedUserLogin = sessionStorage.getItem('userLogin');
        return storedUserLogin ? JSON.parse(storedUserLogin) : false;
    });


    useEffect(() => {
        // Save user login state to localStorage whenever it changes
        sessionStorage.setItem('userLogin', JSON.stringify(userLogin));
    }, [userLogin]);

    const loginUser = () => {
        setUserLogin(true);
    };

    const logoutUser = () => {
        sessionStorage.clear();
        setUserLogin(false);

    };

    return (
        <UserContext.Provider value={{ userLogin, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };